import {
  DIALOG_SUBSCRIPTION_OPEN,
  DIALOG_SUBSCRIPTION_CLOSE,
  DIALOG_ACCOUNT_OPEN,
  DIALOG_ACCOUNT_CLOSE,
  DIALOG_DELETE_ACCOUNT_OPEN,
  DIALOG_DELETE_ACCOUNT_CLOSE,
  PURCHASE_SUBSCRIPTION_START,
  PURCHASE_SUBSCRIPTION_FINISH,
  DIALOG_SETTINGS_OPEN,
  DIALOG_SETTINGS_CLOSE,
  CANCEL_SUBSCRIPTION_START,
  CANCEL_SUBSCRIPTION_FINISH,
  CHANGE_ACCOUNT_EMAIL_START,
  CHANGE_ACCOUNT_EMAIL_FINISH,
  DELETE_ACCOUNT_START,
  DELETE_ACCOUNT_FINISH,
  LOGOUT_START,
  LOGOUT_FINISH,
} from './actions';

export const initialState = {
  isAccountDialogOpen: false,
  isAccountDialogLoading: false,
  isDeleteAccountDialogOpen: false,
  isDeleteAccountDialogLoading: false,
  isSettingsDialogOpen: false,
  isSettingsDialogLoading: false,
  isMenuLoading: false,
  isSubscriptionDialogOpen: false,
  isSubscriptionDialogLoading: false,
  purchaseResult: {},
};

const headerReducer = (state, action) => {
  switch (action.type) {
    case DIALOG_ACCOUNT_OPEN:
      return {
        ...state,
        isSubscriptionDialogOpen: false,
        isAccountDialogOpen: true,
      };

    case DIALOG_ACCOUNT_CLOSE:
      return {
        ...state,
        isAccountDialogOpen: false,
      };

    case DIALOG_DELETE_ACCOUNT_OPEN:
      return {
        ...state,
        isDeleteAccountDialogOpen: true,
      };

    case DIALOG_DELETE_ACCOUNT_CLOSE:
      return {
        ...state,
        isDeleteAccountDialogOpen: false,
      };

    case DIALOG_SETTINGS_OPEN:
      return {
        ...state,
        isSettingsDialogOpen: true,
      };

    case DIALOG_SETTINGS_CLOSE:
      return {
        ...state,
        isSettingsDialogOpen: false,
      };

    case DIALOG_SUBSCRIPTION_OPEN:
      return {
        ...state,
        isSubscriptionDialogOpen: true,
        isAccountDialogOpen: false,
      };

    case DIALOG_SUBSCRIPTION_CLOSE:
      return {
        ...state,
        isSubscriptionDialogOpen: false,
      };

    case CHANGE_ACCOUNT_EMAIL_START:
      return {
        ...state,
        isAccountDialogLoading: true,
      };

    case CHANGE_ACCOUNT_EMAIL_FINISH:
      return {
        ...state,
        isAccountDialogLoading: false,
      };

    case DELETE_ACCOUNT_START:
      return {
        ...state,
        isDeleteAccountDialogLoading: true,
      };

    case DELETE_ACCOUNT_FINISH:
      return {
        ...initialState,
      };

    case PURCHASE_SUBSCRIPTION_START:
      return {
        ...state,
        isSubscriptionDialogLoading: true,
      };

    case PURCHASE_SUBSCRIPTION_FINISH:
      return {
        ...initialState,
        purchaseResult: action.data,
      };

    case CANCEL_SUBSCRIPTION_START:
      return {
        ...state,
        isSubscriptionDialogLoading: true,
      };

    case CANCEL_SUBSCRIPTION_FINISH:
      return {
        ...initialState,
      };

    case LOGOUT_START:
      return {
        ...state,
        isMenuLoading: true,
      };

    case LOGOUT_FINISH:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default headerReducer;
