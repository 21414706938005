import React, { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import RouteDelayView from './RouteDelayView';

import { useAccountStatus } from '../../Account';

const RouteDelayContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const { isLoading } = useAccountStatus();

  useEffect(() => {
    if (isLoading === false) {
      history.replace(location.pathname);
    }
  }, [
    history,
    location,
    isLoading,
  ]);

  return (
    <RouteDelayView />
  );
};

export default RouteDelayContainer;
