import React from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import { applyMiddleware, combineReducers, compose, createStore } from "redux";

import {
  firebaseReducer,
  getFirebase,
  ReactReduxFirebaseProvider,
} from "react-redux-firebase";

import { firestoreReducer, createFirestoreInstance } from "redux-firestore";

import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';

import App from "./App";

import { isDev } from "../config";

const middleware = [thunk.withExtraArgument({ getFirebase })];

const createStoreWithMiddleware = compose(applyMiddleware(...middleware))(
  createStore
);

const store = createStoreWithMiddleware(
  combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  })
);

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
};

if (isDev()) {
  firebase.initializeApp({
    apiKey: "AIzaSyALP61N8FCRWgNBBgipxXLczztrp1flOEs",
    authDomain: "screwnotes-dev.firebaseapp.com",
    databaseURL: "https://screwnotes-dev.firebaseio.com",
    projectId: "screwnotes-dev",
    storageBucket: "screwnotes-dev.appspot.com",
    messagingSenderId: "370263931829",
    appId: "1:370263931829:web:766a7d1133bcc4e8c9b7fc",
    measurementId: "G-PPFBE2JRPP",
  });
} else {
  firebase.initializeApp({
    apiKey: "AIzaSyBxN94w-WAPimHCMDiaVRn5eTN33W63PAE",
    authDomain: "screwnotes.firebaseapp.com",
    databaseURL: "https://screwnotes.firebaseio.com",
    projectId: "screwnotes",
    storageBucket: "screwnotes.appspot.com",
    messagingSenderId: "606620307763",
    appId: "1:606620307763:web:ab1731665e0d5ea4692e46",
    measurementId: "G-YZVWQ2H4LM",
  });
}

firebase.firestore();
firebase.functions();
firebase.analytics();

const stripeKey = isDev()
  ? "pk_test_Uek7uVHTlSM6LTlyVpfvomnh00vhHM3CJB"
  : "pk_live_em1nRpJgSZUPrLzCAgJ8LBmw00gxYmsEkK";

const stripePromise = loadStripe(stripeKey);

function Root() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider
        firebase={firebase}
        config={rrfConfig}
        dispatch={store.dispatch}
        createFirestoreInstance={createFirestoreInstance}
      >
        <Elements stripe={stripePromise}>
          <Router>
            <Route path="/:filter?" component={App} />
          </Router>
        </Elements>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

export default Root;
