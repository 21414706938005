import React, { useState, useContext } from 'react';
import propTypes from 'prop-types';

import SignInDialogView from './SignInDialogView';

import { useInternalLogin } from '../../Account';
import LoginContext from '../context';

import {
  dialogPasswordResetOpen,
  dialogSignInClose,
  signInInternalStart,
  signInInternalFinish,
} from '../actions';

function SignInDialogContainer(props) {
  const {
    isFullScreen,
    isSignInLoading,
    isSignInOpen,
    onRedirect,
  } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useContext(LoginContext);

  const signIn = useInternalLogin(
    (error) => {
      setErrorMessage(error.message);

      dispatch(signInInternalFinish());
    },
    () => {
      onRedirect();

      dispatch(signInInternalFinish());
    },
  );

  const handleClose = () => dispatch(dialogSignInClose());

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleClickSignIn = (e) => {
    e.preventDefault();

    dispatch(signInInternalStart());

    signIn(email, password);

    setPassword('');
    setErrorMessage('');
  };

  const handleError = (error) => setErrorMessage(error.message);

  const handlePasswordForgotten = () => dispatch(dialogPasswordResetOpen());

  return (
    <SignInDialogView
      isFullScreen={isFullScreen}
      isSignInLoading={isSignInLoading}
      isSignInOpen={isSignInOpen}
      signInErrorMessage={errorMessage}
      onClickSignIn={handleClickSignIn}
      onClose={handleClose}
      onEmailChange={handleEmailChange}
      onPasswordChange={handlePasswordChange}
      onPasswordForgotten={handlePasswordForgotten}
      onError={handleError}
      onRedirect={onRedirect}
    />
  );
}

SignInDialogContainer.propTypes = {
  isFullScreen: propTypes.bool,
  isSignInLoading: propTypes.bool,
  isSignInOpen: propTypes.bool,
  onRedirect: propTypes.func.isRequired,
};

SignInDialogContainer.defaultProps = {
  isFullScreen: false,
  isSignInLoading: false,
  isSignInOpen: false,
};

export default SignInDialogContainer;
