import React from 'react';
import propTypes from 'prop-types';

import MenuView from './MenuView';

import { useSubscriptionStatus } from '../../Account';

function MenuContainer(props) {
  const {
    isFullScreen,
    isMenuOpen,
    anchorEl,
    onMenuClose,
    onClickSubscription,
    onClickAccount,
    onClickSettings,
    onClickLogout,
    isSubscriptionDialogOpen,
    isSubscriptionDialogLoading,
    isAccountDialogOpen,
    isAccountDialogLoading,
    isSettingsDialogOpen,
    isSettingsDialogLoading,
    isDeleteAccountDialogOpen,
    isDeleteAccountDialogLoading,
  } = props;

  const hasSubscription = useSubscriptionStatus();

  return (
    <MenuView
      isFullScreen={isFullScreen}
      isMenuOpen={isMenuOpen}
      hasSubscription={hasSubscription}
      anchorEl={anchorEl}
      onMenuClose={onMenuClose}
      onClickSubscription={onClickSubscription}
      onClickSettings={onClickSettings}
      onClickAccount={onClickAccount}
      onClickLogout={onClickLogout}
      isSubscriptionDialogOpen={isSubscriptionDialogOpen}
      isSubscriptionDialogLoading={isSubscriptionDialogLoading}
      isAccountDialogOpen={isAccountDialogOpen}
      isAccountDialogLoading={isAccountDialogLoading}
      isSettingsDialogOpen={isSettingsDialogOpen}
      isSettingsDialogLoading={isSettingsDialogLoading}
      isDeleteAccountDialogOpen={isDeleteAccountDialogOpen}
      isDeleteAccountDialogLoading={isDeleteAccountDialogLoading}
    />
  );
}

MenuContainer.propTypes = {
  isFullScreen: propTypes.bool,
  isMenuOpen: propTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: propTypes.object,
  onMenuClose: propTypes.func.isRequired,
  onClickSubscription: propTypes.func.isRequired,
  onClickAccount: propTypes.func.isRequired,
  onClickLogout: propTypes.func.isRequired,
  onClickSettings: propTypes.func.isRequired,
  isSubscriptionDialogOpen: propTypes.bool,
  isSubscriptionDialogLoading: propTypes.bool,
  isSettingsDialogOpen: propTypes.bool,
  isSettingsDialogLoading: propTypes.bool,
  isAccountDialogOpen: propTypes.bool,
  isAccountDialogLoading: propTypes.bool,
  isDeleteAccountDialogOpen: propTypes.bool,
  isDeleteAccountDialogLoading: propTypes.bool,
};

MenuContainer.defaultProps = {
  isFullScreen: false,
  isMenuOpen: false,
  anchorEl: null,
  isSubscriptionDialogOpen: false,
  isSubscriptionDialogLoading: false,
  isAccountDialogOpen: false,
  isAccountDialogLoading: false,
  isSettingsDialogOpen: false,
  isSettingsDialogLoading: false,
  isDeleteAccountDialogOpen: false,
  isDeleteAccountDialogLoading: false,
};

export default MenuContainer;
