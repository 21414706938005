import React from 'react';
import propTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DialogTitle } from '@mui/material';

import useStyles from './styles';

function PasswordResetDialogView(props) {
  const {
    isPasswordResetOpen,
    isPasswordResetLoading,
    isPasswordResetSuccess,
    passwordResetErrorMessage,
    onClickPasswordReset,
    onClose,
    onEmailChange,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={isPasswordResetOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        Reset Password
      </DialogTitle>
      <DialogContent>
        <div className={classes.sectionMiddle}>
          <form
            onSubmit={onClickPasswordReset}
          >
            <div>
              <TextField
                id="resetPasswordEmail"
                label="Email"
                fullWidth
                required
                onChange={onEmailChange}
              />
            </div>
            <div className={classes.sectionBottomCentered}>
              <Button
                className={classes.blueGreyButton}
                type="submit"
                variant="contained"
                disabled={isPasswordResetLoading}
                size="large"
              >
                Request Password Reset
              </Button>
            </div>
          </form>
        </div>
        {isPasswordResetSuccess && (
          <div className={classes.sectionBottom}>
            <Alert severity="success">
              <Typography>
                Your password reset request has been submitted. Please
                check your email for a link to reset your password if
                you have a valid account.
              </Typography>
            </Alert>
          </div>
        )}
        {passwordResetErrorMessage && (
          <div className={classes.sectionBottom}>
            <Alert severity="error">
              <Typography>
                {passwordResetErrorMessage}
              </Typography>
            </Alert>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

PasswordResetDialogView.propTypes = {
  isPasswordResetOpen: propTypes.bool,
  isPasswordResetLoading: propTypes.bool,
  isPasswordResetSuccess: propTypes.bool,
  passwordResetErrorMessage: propTypes.string,
  onClickPasswordReset: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  onEmailChange: propTypes.func.isRequired,
};

PasswordResetDialogView.defaultProps = {
  isPasswordResetOpen: false,
  isPasswordResetLoading: false,
  isPasswordResetSuccess: false,
  passwordResetErrorMessage: '',
};

export default PasswordResetDialogView;
