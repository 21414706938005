import { makeStyles } from '@mui/styles';
import { blueGrey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    marginBottom: theme.spacing(2),
  },
  sectionMiddle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  sectionBottom: {
    marginTop: theme.spacing(2),
  },
  sectionBottomCentered: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  blueGreyButton: {
    color: blueGrey[500],
    // backgroundColor: blueGrey[500],
    // '&:hover': {
    //   backgroundColor: blueGrey[700],
    // },
  },
}));

export default useStyles;
