import React, { useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';

import EventToastView from './EventToastView';

import { eventNext, toastClose } from '../actions';
import EventListenerContext from '../context';

function EventToastContainer(props) {
  const {
    isOpen,
    severity,
    message,
    autoHideDuration,
  } = props;

  const [isClosing, setIsClosing] = useState(false);

  const dispatch = useContext(EventListenerContext);

  useEffect(() => {
    if (isClosing === true) {
      dispatch(toastClose());

      setTimeout(() => {
        dispatch(eventNext());
      }, 1000);

      setIsClosing(false);
    }
  }, [dispatch, isClosing]);

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsClosing(true);
  };

  return (
    <EventToastView
      isOpen={isOpen}
      severity={severity}
      message={message}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
    />
  );
}

EventToastContainer.propTypes = {
  isOpen: propTypes.bool,
  severity: propTypes.string,
  message: propTypes.string,
  autoHideDuration: propTypes.number,
};

EventToastContainer.defaultProps = {
  isOpen: false,
  severity: '',
  message: '',
  autoHideDuration: 3000,
};

export default EventToastContainer;
