import React, { useMemo, useReducer } from 'react';
import propTypes from 'prop-types';

import PageListView from './PageListView';

import { useSubscriptionStatus } from '../Account';
import PageListContext from './context';
import pageListReducer, { initialState } from './reducer';
import { useSafeDispatch } from '../../utils';
import {
  usePages,
  useArePagesLoading,
  useAddPage,
  useArchivedPages,
} from './hooks';
import { useEventEmitter, SEVERITY_ERROR } from '../EventListener';

function PageListContainer(props) {
  const { isFullScreen, notMobile } = props;

  const [state, dispatch] = useReducer(pageListReducer, initialState);

  const safeDispatch = useSafeDispatch(dispatch);

  const hasSubscription = useSubscriptionStatus();
  const pages = usePages();
  const archivedPages = useArchivedPages();
  const arePagesLoading = useArePagesLoading();
  const emitEvent = useEventEmitter();

  const addPage = useAddPage(
    (error) => emitEvent(
      SEVERITY_ERROR,
      error.message,
    ),
    () => window.scrollTo(0, 0),
  );

  const {
    id: pageId,
    title,
    items,
    isArchived,
  } = useMemo(() => ((!arePagesLoading && state.activePageId)
    ? pages.find((page) => page.id === state.activePageId)
    : {
      id: '',
      title: '',
      isArchived: false,
    }
  ), [
    arePagesLoading,
    state.activePageId,
    pages,
  ]);

  const {
    id: itemId,
    content,
  } = useMemo(() => ((!arePagesLoading && state.activePageItemId)
    ? items.find((item) => item.id === state.activePageItemId)
    : {
      id: '',
      content: '',
    }
  ), [
    arePagesLoading,
    state.activePageItemId,
    items,
  ]);

  const handleClickAddNewPage = (e) => addPage(e);

  return (
    <PageListContext.Provider value={safeDispatch}>
      <PageListView
        isFullScreen={isFullScreen}
        notMobile={notMobile}
        arePagesLoading={arePagesLoading}
        hasSubscription={hasSubscription}
        isPageEditOpen={state.isPageEditOpen}
        isPageItemEditOpen={state.isPageItemEditOpen}
        onClickAddNewPage={handleClickAddNewPage}
        activePageId={String(pageId)} // casting to String to suppress the type change errors
        activePageTitle={title}
        isActivePageArchived={isArchived}
        activePageItemId={String(itemId)}
        activePageItemContent={content}
        pages={pages}
        archivedPages={archivedPages}
      />
    </PageListContext.Provider>
  );
}

PageListContainer.propTypes = {
  isFullScreen: propTypes.bool,
  notMobile: propTypes.bool,
};

PageListContainer.defaultProps = {
  isFullScreen: false,
  notMobile: false,
};

export default PageListContainer;
