/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import thunk from 'redux-thunk';

import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';

import { firebaseReducer, getFirebase } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import * as serviceWorker from './serviceWorker';

import Root from './components/Root';

import './index.css';

const middleware = [
  thunk.withExtraArgument({ getFirebase }),
];

const createStoreWithMiddleware = compose(
  applyMiddleware(...middleware),
)(createStore);

const store = createStoreWithMiddleware(combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
}));

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

root.render(<Root store={store} />);

// ReactDOM.render(
//   <Root store={store} />,
//   document.getElementById('root'),
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
