import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  visible: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 1s linear',
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s 1s, opacity 1s linear',
  },
  container: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
