import { makeStyles } from '@mui/styles';
import {
  red,
  blueGrey,
  lightGreen,
} from '@mui/material/colors';

const useStyles = makeStyles(() => ({
  blueGreyButton: {
    color: blueGrey[500],
  },
  greenButton: {
    color: lightGreen[500],
  },
  redButton: {
    color: red[500],
  },
}));

export default useStyles;
