import { makeStyles } from '@mui/styles';
import { blueGrey, lightGreen } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    marginBottom: theme.spacing(2),
  },
  sectionMiddle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  sectionBottom: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  color: theme.palette.getContrastText(lightGreen[500]),
  backgroundColor: lightGreen[500],
  '&:hover': {
    backgroundColor: lightGreen[700],
  },
  submitButton: {
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    '&:hover': {
      backgroundColor: lightGreen[700],
    },
  },
  blueGreyButton: {
    color: blueGrey[500],
    // backgroundColor: blueGrey[500],
    // '&:hover': {
    //   backgroundColor: blueGrey[700],
    // },
  },
}));

export default useStyles;
