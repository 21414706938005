import { makeStyles } from '@mui/styles';
import { blueGrey, red, blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  blueGreyButton: {
    color: blueGrey[500],
    // backgroundColor: blueGrey[500],
    // '&:hover': {
    //   backgroundColor: blueGrey[700],
    // },
  },
  blueButton: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
  errorButton: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}));

export default useStyles;
