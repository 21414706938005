import React from 'react';

import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import useStyles from './styles';

const getIcon = (id) => {
  if (id === 1) {
    return '/screw-half.png';
  } if (id === 2) {
    return '/screw-done.png';
  }
  return '/screw-todo.png';
};

function AboutView() {
  const classes = useStyles();

  return (
    <Card
      className={classes.card}
      variant="outlined"
    >
      <CardHeader title="Welcome to Screw Notes" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Screw Notes are the most simple way to track action
              items, todos, lists, or just plain old notes.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Why are they called screw notes? Because every item or note has a
              screw head next to it, like this:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" />
            <ListItem
              role={undefined}
              dense
            >
              <ListItemAvatar>
                <Avatar
                  alt="To Do"
                  src={getIcon(0)}
                  className={classes.small}
                />
              </ListItemAvatar>
              <ListItemText>
                A new item!
              </ListItemText>
            </ListItem>
            <Divider variant="middle" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Once you&apos;ve started working on the item, just right-click or long tap the
              screw to mark it as &quot;in progress&quot;:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" />
            <ListItem
              role={undefined}
              dense
            >
              <ListItemAvatar>
                <Avatar
                  alt="To Do"
                  src={getIcon(1)}
                  className={classes.small}
                />
              </ListItemAvatar>
              <ListItemText>
                An in progress item!
              </ListItemText>
            </ListItem>
            <Divider variant="middle" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              And once you&apos;ve completed the item, just left-click or tap the screw
              again to mark is as &quot;complete&quot;:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" />
            <ListItem
              role={undefined}
              dense
            >
              <ListItemAvatar>
                <Avatar
                  alt="To Do"
                  src={getIcon(2)}
                  className={classes.small}
                />
              </ListItemAvatar>
              <ListItemText>
                <strike>A new item, now completed!</strike>
              </ListItemText>
            </ListItem>
            <Divider variant="middle" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Screw Notes are designed to be as simple as possible, to let you
              unclutter your brain as quickly as possible.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Sign up now for free to try it out!
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AboutView;
