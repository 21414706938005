import React from 'react';
import propTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Loadable from '../Loadable';

import useStyles from './styles';

function EmailVerificationView(props) {
  const {
    isLoading,
    isEmailProvided,
    isEmailUpdated,
    isEmailVerified,
    isEmailVerificationSent,
    emailUpdateError,
    emailVerificationError,
    onEmailChange,
    onClickEmailUpdate,
    onClickEmailVerify,
  } = props;

  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      <Card className={classes.card} variant="outlined">
        <CardHeader title="Verify Email Address" />
        <CardContent>
          <Loadable isLoading={isLoading}>
            {!isEmailProvided && (
              <>
                <Typography>
                  An email address is required to use Screw Notes. Please provide your email
                  address before you continue.
                </Typography>
                <div
                  className={classes.section}
                >
                  <TextField
                    autoFocus
                    margin="dense"
                    id="emailAddress"
                    label="Email Address"
                    fullWidth
                    onChange={onEmailChange}
                  />
                </div>
                <div
                  className={classes.section}
                >
                  {emailUpdateError && (
                  <Alert severity="error">
                    <Typography>
                      {emailUpdateError}
                    </Typography>
                  </Alert>
                  )}
                </div>
                <div
                  className={classes.section}
                >
                  {!isEmailUpdated ? (
                    <Button className={classes.submitButton} onClick={onClickEmailUpdate}>
                      Save Email Address
                    </Button>
                  ) : (
                    <Alert severity="success">
                      Email Updated! Reload the app to check again.
                    </Alert>
                  )}
                </div>
              </>
            )}
            {isEmailProvided && !isEmailVerified && (
              <>
                <Typography>
                  A verified email address is required to use Screw Notes. Please check
                  your email address for a verification request from Screw Notes. If
                  you did not get an email, please click the below button to send another.
                </Typography>
                <div
                  className={classes.section}
                >
                  {emailVerificationError && (
                  <Alert severity="error">
                    <Typography>
                      {emailVerificationError}
                    </Typography>
                  </Alert>
                  )}
                </div>
                <div
                  className={classes.section}
                >
                  {!isEmailVerificationSent ? (
                    <Button className={classes.submitButton} onClick={onClickEmailVerify}>
                      Send Email Verification
                    </Button>
                  ) : (
                    <Alert severity="success">
                      Email Verification Sent! Reload this page once
                      you have clicked the verification link
                    </Alert>
                  )}
                </div>
              </>
            )}
          </Loadable>
        </CardContent>
      </Card>
    </Container>
  );
}

EmailVerificationView.propTypes = {
  isLoading: propTypes.bool,
  isEmailProvided: propTypes.bool,
  isEmailUpdated: propTypes.bool,
  isEmailVerified: propTypes.bool,
  isEmailVerificationSent: propTypes.bool,
  emailUpdateError: propTypes.string,
  emailVerificationError: propTypes.string,
  onEmailChange: propTypes.func.isRequired,
  onClickEmailUpdate: propTypes.func.isRequired,
  onClickEmailVerify: propTypes.func.isRequired,
};

EmailVerificationView.defaultProps = {
  isLoading: false,
  isEmailProvided: false,
  isEmailUpdated: false,
  isEmailVerified: false,
  isEmailVerificationSent: false,
  emailUpdateError: '',
  emailVerificationError: '',
};

export default EmailVerificationView;
