import React from 'react';
import propTypes from 'prop-types';

import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faGoogle,
  faFacebook,
  faGithub,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import {
  AUTH_GOOGLE,
  AUTH_FACEBOOK,
  AUTH_GITHUB,
  AUTH_TWITTER,
} from '../../Account';

import useStyles from './styles';

function ExternalIdpListView(props) {
  const { onClickExternalSignIn } = props;

  const classes = useStyles();

  return (
    <div className={classes.sectionMiddle}>
      <ButtonGroup
        orientation="vertical"
        aria-label="sign in group"
        color="primary"
      >
        <Button
          variant="contained"
          size="large"
          onClick={onClickExternalSignIn(AUTH_GOOGLE)}
          startIcon={<FontAwesomeIcon icon={faGoogle} />}
        >
          Continue With Google
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={onClickExternalSignIn(AUTH_FACEBOOK)}
          startIcon={<FontAwesomeIcon icon={faFacebook} />}
        >
          Continue With Facebook
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={onClickExternalSignIn(AUTH_GITHUB)}
          startIcon={<FontAwesomeIcon icon={faGithub} />}
        >
          Continue With Github
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={onClickExternalSignIn(AUTH_TWITTER)}
          startIcon={<FontAwesomeIcon icon={faTwitter} />}
        >
          Continue With Twitter
        </Button>
      </ButtonGroup>
    </div>
  );
}

ExternalIdpListView.propTypes = {
  onClickExternalSignIn: propTypes.func.isRequired,
};

export default ExternalIdpListView;
