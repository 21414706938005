import {
  DIALOG_PAGE_EDIT_OPEN,
  DIALOG_PAGE_EDIT_CLOSE,
  DIALOG_PAGE_ITEM_EDIT_OPEN,
  DIALOG_PAGE_ITEM_EDIT_CLOSE,
} from './actions';

export const initialState = {
  isPageEditOpen: false,
  isPageItemEditOpen: false,
  activePageId: '',
  activePageTitle: '',
  activePageItemId: '',
};

const pageListReducer = (state, action) => {
  switch (action.type) {
    case DIALOG_PAGE_EDIT_OPEN:
      return {
        ...initialState,
        isPageEditOpen: true,
        activePageId: action.pageId,
        activePageTitle: action.pageTitle,
      };

    case DIALOG_PAGE_EDIT_CLOSE:
      return {
        ...initialState,
      };

    case DIALOG_PAGE_ITEM_EDIT_OPEN:
      return {
        ...initialState,
        isPageItemEditOpen: true,
        activePageId: action.pageId,
        activePageItemId: action.itemId,
      };

    case DIALOG_PAGE_ITEM_EDIT_CLOSE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default pageListReducer;
