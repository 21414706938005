import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: 160,
    justifyContent: 'center',
    flexGrow: 1,
  },
  visible: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.2s linear',
    pointerEvents: 'auto',
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s 0.2s, opacity 0.2s linear',
  },
  buttonDisabled: {
    pointerEvents: 'none',
  },
}));

export default useStyles;
