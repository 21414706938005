export const DIALOG_SUBSCRIPTION_OPEN = 'DIALOG_SUBSCRIPTION_OPEN';
export const DIALOG_SUBSCRIPTION_CLOSE = 'DIALOG_SUBSCRIPTION_CLOSE';

export const dialogSubscriptionOpen = () => ({ type: DIALOG_SUBSCRIPTION_OPEN });
export const dialogSubscriptionClose = () => ({ type: DIALOG_SUBSCRIPTION_CLOSE });

export const DIALOG_ACCOUNT_OPEN = 'DIALOG_ACCOUNT_OPEN';
export const DIALOG_ACCOUNT_CLOSE = 'DIALOG_ACCOUNT_CLOSE';

export const dialogAccountOpen = () => ({ type: DIALOG_ACCOUNT_OPEN });
export const dialogAccountClose = () => ({ type: DIALOG_ACCOUNT_CLOSE });

export const DIALOG_SETTINGS_OPEN = 'DIALOG_SETTINGS_OPEN';
export const DIALOG_SETTINGS_CLOSE = 'DIALOG_SETTINGS_CLOSE';

export const dialogSettingsOpen = () => ({ type: DIALOG_SETTINGS_OPEN });
export const dialogSettingsClose = () => ({ type: DIALOG_SETTINGS_CLOSE });

export const DIALOG_DELETE_ACCOUNT_OPEN = 'DIALOG_DELETE_ACCOUNT_OPEN';
export const DIALOG_DELETE_ACCOUNT_CLOSE = 'DIALOG_DELETE_ACCOUNT_CLOSE';

export const dialogDeleteAccountOpen = () => ({ type: DIALOG_DELETE_ACCOUNT_OPEN });
export const dialogDeleteAccountClose = () => ({ type: DIALOG_DELETE_ACCOUNT_CLOSE });

export const PURCHASE_SUBSCRIPTION_START = 'PURCHASE_SUBSCRIPTION_START';
export const PURCHASE_SUBSCRIPTION_FINISH = 'PURCHASE_SUBSCRIPTION_FINISH';

export const purchaseSubscriptionStart = () => ({ type: PURCHASE_SUBSCRIPTION_START });
export const purchaseSubscriptionFinish = () => ({ type: PURCHASE_SUBSCRIPTION_FINISH });

export const CANCEL_SUBSCRIPTION_START = 'CANCEL_SUBSCRIPTION_START';
export const CANCEL_SUBSCRIPTION_FINISH = 'CANCEL_SUBSCRIPTION_FINISH';

export const cancelSubscriptionStart = () => ({ type: CANCEL_SUBSCRIPTION_START });
export const cancelSubscriptionFinish = () => ({ type: CANCEL_SUBSCRIPTION_FINISH });

export const CHANGE_ACCOUNT_EMAIL_START = 'CHANGE_ACCOUNT_EMAIL_START';
export const CHANGE_ACCOUNT_EMAIL_FINISH = 'CHANGE_ACCOUNT_EMAIL_FINISH';

export const changeAccountEmailStart = () => ({ type: CHANGE_ACCOUNT_EMAIL_START });
export const changeAccountEmailFinish = () => ({ type: CHANGE_ACCOUNT_EMAIL_FINISH });

export const DELETE_ACCOUNT_START = 'DELETE_ACCOUNT_START';
export const DELETE_ACCOUNT_FINISH = 'DELETE_ACCOUNT_FINISH';

export const deleteAccountStart = () => ({ type: DELETE_ACCOUNT_START });
export const deleteAccountFinish = () => ({ type: DELETE_ACCOUNT_FINISH });

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_FINISH = 'LOGOUT_FINISH';

export const logOutStart = () => ({ type: LOGOUT_START });
export const logOutFinish = () => ({ type: LOGOUT_FINISH });
