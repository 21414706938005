import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import propTypes from 'prop-types';

import { useSignUp } from '../../Account';
import LoginContext from '../context';

import {
  dialogSignUpClose,
  signUpStart,
  signUpFinish,
} from '../actions';

import SignUpDialogView from './SignUpDialogView';

function SignUpDialogContainer(props) {
  const {
    isFullScreen,
    isSignUpOpen,
    isSignUpLoading,
    onRedirect,
  } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeated, setPasswordRepeated] = useState('');
  const [hasPasswordRepeatedHadFocus, setHasPasswordRepeatedHadFocus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useContext(LoginContext);

  const signUp = useSignUp(
    (error) => {
      setErrorMessage(error.message);

      dispatch(signUpFinish());
    },
    () => {
      onRedirect();

      dispatch(signUpFinish());
    },
  );

  const arePasswordsMatching = useMemo(
    () => password === passwordRepeated,
    [password, passwordRepeated],
  );

  const handleClickSignUp = (e) => {
    e.preventDefault();

    if (arePasswordsMatching) {
      dispatch(signUpStart());

      signUp(email, password);
    }
  };

  const handleClose = () => dispatch(dialogSignUpClose());

  const handlePasswordRepeatedFocus = useCallback(
    () => {
      if (!hasPasswordRepeatedHadFocus) {
        setHasPasswordRepeatedHadFocus(true);
      }
    },
    [hasPasswordRepeatedHadFocus],
  );

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handlePasswordRepeatedChange = (e) => setPasswordRepeated(e.target.value);

  const handleError = useCallback(
    (error) => setErrorMessage(error.message),
    [],
  );

  return (
    <SignUpDialogView
      isFullScreen={isFullScreen}
      isSignUpOpen={isSignUpOpen}
      isSignUpLoading={isSignUpLoading}
      arePasswordsMatching={arePasswordsMatching}
      hasPasswordRepeatedHadFocus={hasPasswordRepeatedHadFocus}
      signUpErrorMessage={errorMessage}
      onClickSignUp={handleClickSignUp}
      onClose={handleClose}
      onEmailChange={handleEmailChange}
      onPasswordChange={handlePasswordChange}
      onPasswordRepeatedChange={handlePasswordRepeatedChange}
      onPasswordRepeatedFocus={handlePasswordRepeatedFocus}
      onError={handleError}
      onRedirect={onRedirect}
    />
  );
}

SignUpDialogContainer.propTypes = {
  isFullScreen: propTypes.bool,
  isSignUpOpen: propTypes.bool,
  isSignUpLoading: propTypes.bool,
  onRedirect: propTypes.func.isRequired,
};

SignUpDialogContainer.defaultProps = {
  isFullScreen: false,
  isSignUpOpen: false,
  isSignUpLoading: false,
};

export default SignUpDialogContainer;
