import React from 'react';
import propTypes from 'prop-types';
import {
  CardElement,
  // useStripe,
  // useElements,
} from '@stripe/react-stripe-js';

import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import UpdateIcon from '@mui/icons-material/Update';
import { DialogTitle } from '@mui/material';

import Loadable from '../../../Loadable';

import useStyles from './styles';
import countries from './countries';

function SubscriptionDialogView(props) {
  const {
    isFullScreen,
    isSubscriptionDialogOpen,
    isSubscriptionDialogLoading,
    hasSubscription,
    onPurchaseSubscription,
    onCancelSubscription,
    onCloseSubscriptionDialog,
  } = props;

  const [countryValue, setCountryValue] = React.useState(countries[0]);
  const [inputValue, setInputValue] = React.useState('');
  const classes = useStyles();

  const cancelDialogContent = (
    <>
      <DialogTitle>
        Cancel Your Subscription
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you cancel your subscription all Pro features will be removed from
          your account after the last payment period ends.
        </DialogContentText>
        <DialogContentText>
          If you are cancelling your subscription because you are not happy with Screw Notes or
          something is not working properly, please let us know by contacting support (
          <a href="mailto:support@screwnotes.com">support@screwnotes.com</a>
          ) and we will try our best to help you.
        </DialogContentText>
      </DialogContent>
    </>
  );

  const purchaseDialogContent = (
    <>
      <DialogTitle>
        Get Pro
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => onPurchaseSubscription(e, CardElement, countryValue)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="p" variant="body1">
                Unlock the full capabilities of Screw Notes today with a Pro subscription!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary="AUD$12/Year (+10% GST for Australian Residents)" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <AllInclusiveIcon />
                  </ListItemIcon>
                  <ListItemText primary="Unlimited Pages" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <AllInclusiveIcon />
                  </ListItemIcon>
                  <ListItemText primary="Unlimited Items per Page" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <UpdateIcon />
                  </ListItemIcon>
                  <ListItemText primary="All new features as we add them" />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" variant="body2">
                Please provide your country of residence and your credit card to subscribe. You
                will be charged after a 7 day trial period, and you can cancel at any time.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" variant="body2">
                All payments are processed by Stripe, and we never store your credit card details.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="country-select"
                options={countries}
                value={countryValue}
                onChange={(e, v) => {
                  setCountryValue(v);
                }}
                inputValue={inputValue}
                onInputChange={(e, v) => {
                  setInputValue(v);
                }}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(option) => (
                  <span>{option.label}</span>
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Select your Country"
                    required
                    size="small"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: 10 }}>
                <CardElement />
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button component="a" href="https://stripe.com/" target="_blank">
                <img src="/powered_by_stripe.png" alt="Powered By Stripe" className={classes.logo} />
              </Button>
              <Button
                className={classes.submitButton}
                type="submit"
              >
                Subscribe to Pro
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </>
  );

  return (
    <Dialog
      open={isSubscriptionDialogOpen}
      onClose={onCloseSubscriptionDialog}
      aria-labelledby="form-dialog-title"
      fullScreen={isFullScreen}
    >
      <Loadable isLoading={isSubscriptionDialogLoading}>
        { hasSubscription
          ? cancelDialogContent
          : purchaseDialogContent }
        <DialogActions>
          {hasSubscription && (
          <Button
            className={classes.warningButton}
            onClick={onCancelSubscription}
            size="medium"
            startIcon={<SaveIcon />}
          >
            Cancel Subscription
          </Button>
          )}
        </DialogActions>
      </Loadable>
    </Dialog>
  );
}

SubscriptionDialogView.propTypes = {
  isFullScreen: propTypes.bool,
  isSubscriptionDialogOpen: propTypes.bool,
  isSubscriptionDialogLoading: propTypes.bool,
  hasSubscription: propTypes.bool,
  onPurchaseSubscription: propTypes.func.isRequired,
  onCancelSubscription: propTypes.func.isRequired,
  onCloseSubscriptionDialog: propTypes.func.isRequired,
};

SubscriptionDialogView.defaultProps = {
  isFullScreen: false,
  isSubscriptionDialogOpen: false,
  isSubscriptionDialogLoading: false,
  hasSubscription: false,
};

export default SubscriptionDialogView;
