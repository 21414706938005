import React, { useContext, useState } from 'react';
import propTypes from 'prop-types';

import AccountDialogView from './AccountDialogView';

import { useChangeEmail } from '../../../Account';
import { useEventEmitter, SEVERITY_ERROR } from '../../../EventListener';
import MenuContext from '../../context';

import {
  dialogAccountClose,
  dialogDeleteAccountOpen,
  changeAccountEmailStart,
  changeAccountEmailFinish,
} from '../../actions';

function AccountDialogContainer(props) {
  const {
    isFullScreen,
    isAccountDialogOpen,
    isAccountDialogLoading,
    isDeleteAccountDialogOpen,
    isDeleteAccountDialogLoading,
  } = props;

  const [accountEmail, setAccountEmail] = useState();

  const dispatch = useContext(MenuContext);
  const emitEvent = useEventEmitter();

  const changeEmail = useChangeEmail(
    (error) => {
      dispatch(changeAccountEmailFinish());

      emitEvent(
        SEVERITY_ERROR,
        error.message,
      );
    },
    () => dispatch(changeAccountEmailFinish()),
  );

  const handleClickDeleteAccountDialogOpen = () => dispatch(dialogDeleteAccountOpen());

  const handleCloseAccountDialog = () => dispatch(dialogAccountClose());

  const handleSubmitAccountEmailChange = (e) => {
    e.preventDefault();
    dispatch(changeAccountEmailStart());

    changeEmail(accountEmail);
  };

  const handleChangeAccountEmail = (e) => setAccountEmail(e.target.value);

  return (
    <AccountDialogView
      isFullScreen={isFullScreen}
      isAccountDialogOpen={isAccountDialogOpen}
      isAccountDialogLoading={isAccountDialogLoading}
      isDeleteAccountDialogOpen={isDeleteAccountDialogOpen}
      isDeleteAccountDialogLoading={isDeleteAccountDialogLoading}
      onClickDeleteAccountDialogOpen={handleClickDeleteAccountDialogOpen}
      onCloseAccountDialog={handleCloseAccountDialog}
      onSubmitAccountEmailChange={handleSubmitAccountEmailChange}
      onChangeAccountEmail={handleChangeAccountEmail}
    />
  );
}

AccountDialogContainer.propTypes = {
  isFullScreen: propTypes.bool,
  isAccountDialogOpen: propTypes.bool,
  isAccountDialogLoading: propTypes.bool,
  isDeleteAccountDialogOpen: propTypes.bool,
  isDeleteAccountDialogLoading: propTypes.bool,
};

AccountDialogContainer.defaultProps = {
  isFullScreen: false,
  isAccountDialogOpen: false,
  isAccountDialogLoading: false,
  isDeleteAccountDialogOpen: false,
  isDeleteAccountDialogLoading: false,
};

export default AccountDialogContainer;
