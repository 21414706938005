import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { useFirebase } from 'react-redux-firebase';
import {
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import SubscriptionDialogView from './SubscriptionDialogView';

import { useAccountStatus } from '../../../Account';
import {
  useEventEmitter,
  SEVERITY_ERROR,
  SEVERITY_SUCCESS,
} from '../../../EventListener';
import MenuContext from '../../context';

import {
  dialogSubscriptionClose,
  purchaseSubscriptionStart,
  cancelSubscriptionStart,
  purchaseSubscriptionFinish,
  cancelSubscriptionFinish,
} from '../../actions';

function SubscriptionDialogContainer(props) {
  const {
    isFullScreen,
    hasSubscription,
    isSubscriptionDialogOpen,
    isSubscriptionDialogLoading,
  } = props;

  const elements = useElements();
  const stripe = useStripe();
  const accountStatus = useAccountStatus();
  const firebase = useFirebase();
  const dispatch = useContext(MenuContext);
  const emitEvent = useEventEmitter();

  const handleCloseSubscriptionDialog = () => dispatch(dialogSubscriptionClose());

  const handlePurchaseSubscription = async (e, CardElement, countryValue) => {
    e.preventDefault();
    dispatch(purchaseSubscriptionStart());

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      billing_details: {
        email: accountStatus.customerEmail,
      },
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.error(error);
      emitEvent(SEVERITY_ERROR, error.message);
      dispatch(purchaseSubscriptionFinish());
    } else {
      // call firebase to do the rest
      const data = {
        email: accountStatus.customerEmail,
        paymentMethod: paymentMethod.id,
        country: countryValue.label,
      };
      try {
        const response = await firebase.functions().httpsCallable('createSubscription')(data);
        console.log(response);
        if (response && response.data && response.data.isError === false) {
          emitEvent(SEVERITY_SUCCESS, 'Subscription successful! Your Pro features are available now.');
        } else {
          emitEvent(SEVERITY_ERROR, 'Subscription failed! Please check the form and try again, or contact support if you keep seeing this message.');
        }
        // toast success
      } catch (err) {
        console.error(err);
        emitEvent(SEVERITY_ERROR, err.message);
        // show an error
      }

      dispatch(purchaseSubscriptionFinish());
    }
  };

  const handleCancelSubscription = async () => {
    dispatch(cancelSubscriptionStart());
    const response = await firebase.functions().httpsCallable('cancelSubscription')();
    if (response && response.data && response.data.subscriptionCancelled) {
      emitEvent(SEVERITY_SUCCESS, 'Subscription cancelled successfully!');
    } else {
      emitEvent(SEVERITY_ERROR, 'Subscription cancellation failed! Please try again, or contact support if you keep seeing this message.');
    }
    dispatch(cancelSubscriptionFinish());
  };

  return (
    <SubscriptionDialogView
      isFullScreen={isFullScreen}
      hasSubscription={hasSubscription}
      isSubscriptionDialogOpen={isSubscriptionDialogOpen}
      isSubscriptionDialogLoading={isSubscriptionDialogLoading}
      onCloseSubscriptionDialog={handleCloseSubscriptionDialog}
      onPurchaseSubscription={handlePurchaseSubscription}
      onCancelSubscription={handleCancelSubscription}
    />
  );
}

SubscriptionDialogContainer.propTypes = {
  isFullScreen: propTypes.bool,
  hasSubscription: propTypes.bool,
  isSubscriptionDialogOpen: propTypes.bool,
  isSubscriptionDialogLoading: propTypes.bool,
};

SubscriptionDialogContainer.defaultProps = {
  isFullScreen: false,
  hasSubscription: false,
  isSubscriptionDialogOpen: false,
  isSubscriptionDialogLoading: false,
};

export default SubscriptionDialogContainer;
