import React from 'react';
import propTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DialogTitle } from '@mui/material';

import DeleteAccountDialog from './DeleteAccountDialog';
import Loadable from '../../../Loadable';

import useStyles from './styles';

function AccountDialogView(props) {
  const {
    isFullScreen,
    isAccountDialogOpen,
    isAccountDialogLoading,
    isDeleteAccountDialogOpen,
    isDeleteAccountDialogLoading,
    onClickDeleteAccountDialogOpen,
    onCloseAccountDialog,
    onSubmitAccountEmailChange,
    onChangeAccountEmail,
  } = props;

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={isAccountDialogOpen}
        onClose={onCloseAccountDialog}
        aria-labelledby="form-dialog-title"
        fullScreen={isFullScreen}
      >
        <DialogTitle>
          Edit Account
        </DialogTitle>
        <DialogContent>
          <Loadable isLoading={isAccountDialogLoading}>
            <form onSubmit={onSubmitAccountEmailChange}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Change Email Address
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    If you change your email address you will be required to verify
                    it with a confirmation email to keep using Screw Notes.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    onChange={onChangeAccountEmail}
                    label="New Email Address"
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button
                    className={classes.blueButton}
                    type="submit"
                    variant="contained"
                    size="large"
                    startIcon={<FontAwesomeIcon icon={faEnvelope} />}
                  >
                    Change Email Address
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Delete Your Account
                  </Typography>
                  <Typography variant="subtitle1">
                    Deleting your account will delete all of data, and we won&apos;t be able
                    to restore your account or your data.
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button
                    className={classes.errorButton}
                    onClick={onClickDeleteAccountDialogOpen}
                    variant="contained"
                    size="large"
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    Delete Your Account
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Loadable>
        </DialogContent>
      </Dialog>
      <DeleteAccountDialog
        isFullScreen={isFullScreen}
        isDeleteAccountDialogOpen={isDeleteAccountDialogOpen}
        isDeleteAccountDialogLoading={isDeleteAccountDialogLoading}
      />
    </>
  );
}

AccountDialogView.propTypes = {
  isFullScreen: propTypes.bool,
  isAccountDialogOpen: propTypes.bool,
  isAccountDialogLoading: propTypes.bool,
  isDeleteAccountDialogOpen: propTypes.bool,
  isDeleteAccountDialogLoading: propTypes.bool,
  onClickDeleteAccountDialogOpen: propTypes.func.isRequired,
  onCloseAccountDialog: propTypes.func.isRequired,
  onSubmitAccountEmailChange: propTypes.func.isRequired,
  onChangeAccountEmail: propTypes.func.isRequired,
};

AccountDialogView.defaultProps = {
  isFullScreen: false,
  isAccountDialogOpen: false,
  isAccountDialogLoading: false,
  isDeleteAccountDialogOpen: false,
  isDeleteAccountDialogLoading: false,
};

export default AccountDialogView;
