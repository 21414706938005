import React from 'react';
import propTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { DialogTitle } from '@mui/material';

import Loadable from '../../../Loadable';

function SettingsDialogView(props) {
  const {
    isFullScreen,
    isSettingsDialogOpen,
    isSettingsDialogLoading,
    onCloseAccountDialog,
    darkMode,
    onChangeDarkMode,
    completionMode,
    onChangeCompletionMode,
  } = props;

  return (
    <Dialog
      open={isSettingsDialogOpen}
      onClose={onCloseAccountDialog}
      aria-labelledby="form-dialog-title"
      fullScreen={isFullScreen}
    >
      <DialogTitle
        id="form-dialog-title"
        // onClose={onCloseAccountDialog}
        // isLoading={isSettingsDialogLoading}
      >
        Edit Settings
      </DialogTitle>
      <DialogContent>
        <Loadable isLoading={isSettingsDialogLoading}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Dark Mode
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Force dark mode or light mode, or set it to automatic if you want
                your Operating System settings to turn it on and off. Once you change this
                setting, just refresh this page for the change to take effect.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="dark mode"
                  name="darkmode"
                  value={darkMode}
                  onChange={onChangeDarkMode}
                >
                  <FormControlLabel value="automatic" control={<Radio />} label="Automatic" />
                  <FormControlLabel value="dark" control={<Radio />} label="Dark" />
                  <FormControlLabel value="light" control={<Radio />} label="Light" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                Completion Mode
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                By default, right-clicking or long-tapping an item will mark it
                as In Progress, and left-clicking or tapping an item will mark
                it as Complete. Use this setting to change this mode.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="completion mode"
                  name="completionmode"
                  value={completionMode}
                  onChange={onChangeCompletionMode}
                >
                  <FormControlLabel value="leftClick" control={<Radio />} label="Left-Click (Default)" />
                  <FormControlLabel value="rightClick" control={<Radio />} label="Right-Click" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Loadable>
      </DialogContent>
    </Dialog>
  );
}

SettingsDialogView.propTypes = {
  isFullScreen: propTypes.bool,
  isSettingsDialogOpen: propTypes.bool,
  isSettingsDialogLoading: propTypes.bool,
  onCloseAccountDialog: propTypes.func.isRequired,
  darkMode: propTypes.string,
  onChangeDarkMode: propTypes.func.isRequired,
  completionMode: propTypes.string,
  onChangeCompletionMode: propTypes.func.isRequired,
};

SettingsDialogView.defaultProps = {
  isFullScreen: false,
  isSettingsDialogOpen: false,
  isSettingsDialogLoading: false,
  darkMode: 'automatic',
  completionMode: 'rightClick',
};

export default SettingsDialogView;
