/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import propTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';

import useStyles from './styles';

function LoadableView(props) {
  const { children, isLoading, size } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={isLoading ? classes.hidden : classes.visible}>
        {children}
      </div>
      <div
        hidden={!isLoading}
        style={{
          height: `${size}px`,
          width: `${size}px`,
          position: 'absolute',
          left: '50%',
          marginLeft: `-${size / 2}px`,
          top: '50%',
          marginTop: `-${size / 2}px`,
        }}
      >
        <CircularProgress size={size} />
      </div>
    </div>
  );
}

LoadableView.propTypes = {
  isLoading: propTypes.bool,
  size: propTypes.number,
  children: propTypes.node.isRequired,
};

LoadableView.defaultProps = {
  isLoading: false,
  size: 64,
};

export default LoadableView;
