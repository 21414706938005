import { makeStyles } from '@mui/styles';

import {
  lightGreen,
  red,
  blue,
} from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuItemGreen: {
    color: lightGreen[700],
  },
  menuItemRed: {
    color: red[500],
  },
  menuItemBlue: {
    color: blue[500],
  },
}));

export default useStyles;
