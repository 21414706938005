import { makeStyles } from '@mui/styles';
import { lightGreen } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    marginBottom: theme.spacing(2),
  },
  sectionMiddle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  sectionBottom: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  submitButton: {
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    '&:hover': {
      backgroundColor: lightGreen[700],
    },
  },
  validationField: {
    '& input:valid + fieldset': {
      borderColor: theme.palette.success.main,
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: theme.palette.error.main,
      borderWidth: 2,
    },
  },
}));

export default useStyles;
