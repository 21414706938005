import React from 'react';
import propTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function EventToastView(props) {
  const {
    severity,
    message,
    isOpen,
    onClose,
    autoHideDuration,
  } = props;

  const severityLevel = (() => {
    switch (severity) {
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      case 'success':
        return 'success';
      default:
        return 'info';
    }
  })();

  return (
    <Snackbar
      open={isOpen}
      onClose={onClose}
      elevation={6}
      autoHideDuration={autoHideDuration}
      variant="filled"
    >
      <Alert severity={severityLevel} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
}

EventToastView.propTypes = {
  severity: propTypes.string,
  message: propTypes.string,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  autoHideDuration: propTypes.number.isRequired,
};

EventToastView.defaultProps = {
  severity: '',
  message: '',
};

export default EventToastView;
