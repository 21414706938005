/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import propTypes from 'prop-types';

import ArchiveIcon from '@mui/icons-material/Archive';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import RestoreIcon from '@mui/icons-material/Restore';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { DialogTitle } from '@mui/material';

import useStyles from './styles';

function PageEditDialogView(props) {
  const {
    isFullScreen,
    isPageEditOpen,
    isPageArchived,
    onChangePageTitle,
    onClickPageUpdate,
    onClickPageDelete,
    onClickPageArchive,
    onClickResetAll,
    onClickPageReinstate,
    onClickCompleteAll,
    onClosePageEdit,
    activePageTitle,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={isPageEditOpen}
      onClose={onClosePageEdit}
      aria-labelledby="form-dialog-title"
      fullScreen={isFullScreen}
    >
      <form onSubmit={onClickPageUpdate}>
        <DialogTitle>
          Edit Page
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                You can archive your page at any time. If you wish to
                delete your page, please archive it first. All changes and
                deletions are permanent, and cannot be recovered.
              </Typography>
            </Grid>
            {!isPageArchived && (
              <Grid item xs={12}>
                <Typography variant="h6" component="h2">
                  Rename Page
                </Typography>
                <Typography variant="body1" component="p">
                  Give your page a useful name, such
                  as <i>Project Longhorn</i> or <i>Weekly Shopping List</i>:
                </Typography>
              </Grid>
            )}
            {!isPageArchived && (
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="pageTitle"
                  label="Page Title"
                  fullWidth
                  onChange={onChangePageTitle}
                  value={activePageTitle}
                />
              </Grid>
            )}
            {false && !isPageArchived && (
              <Grid item xs={12}>
                <Typography variant="h6" component="h2">
                  Page Templates
                </Typography>
                <Typography variant="body1" component="p">
                  Page Templates allow you to save the current set of items for your
                  page so you can re-use them (e.g. to save a recurring shopping list, or
                  a weekly checklist). You can also apply an existing template (this will
                  replace all the items in your page).
                </Typography>
              </Grid>
            )}
            {false && !isPageArchived && (
              <Grid item xs={12}>
                <ButtonGroup
                  size="small"
                  fullWidth
                  variant="contained"
                >
                  <Button
                    className={classes.blueGreyButton}
                  >
                    Save as Template
                  </Button>
                  <Button
                    className={classes.blueGreyButton}
                  >
                    Apply Template
                  </Button>
                </ButtonGroup>
              </Grid>
            )}
            {!isPageArchived && (
              <Grid item xs={12}>
                <Typography variant="h6" component="h2">
                  Bulk Edit
                </Typography>
                <Typography variant="body1" component="p">
                  Change all of your page items at once.
                </Typography>
              </Grid>
            )}
            {!isPageArchived && (
              <Grid item xs={12}>
                <ButtonGroup
                  size="small"
                  fullWidth
                  variant="contained"
                >
                  <Button
                    className={classes.blueGreyButton}
                    onClick={onClickResetAll}
                    size="small"
                    startIcon={<RestoreIcon />}
                  >
                    Reset All
                  </Button>
                  <Button
                    className={classes.blueGreyButton}
                    onClick={onClickCompleteAll}
                    size="small"
                    startIcon={<DoneAllIcon />}
                  >
                    Complete All
                  </Button>
                </ButtonGroup>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {isPageArchived && (
            <Button
              className={classes.redButton}
              onClick={onClickPageDelete}
              size="small"
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          )}
          {!isPageArchived && (
            <Button
              className={classes.redButton}
              onClick={onClickPageArchive}
              size="small"
              startIcon={<ArchiveIcon />}
            >
              Archive
            </Button>
          )}
          {!isPageArchived && (
            <Button
              className={classes.greenButton}
              type="submit"
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          )}
          {isPageArchived && (
            <Button
              className={classes.greenButton}
              onClick={onClickPageReinstate}
              startIcon={<UnarchiveIcon />}
            >
              Restore
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>

  );
}

PageEditDialogView.propTypes = {
  isFullScreen: propTypes.bool,
  isPageEditOpen: propTypes.bool,
  isPageArchived: propTypes.bool,
  onChangePageTitle: propTypes.func.isRequired,
  onClickPageUpdate: propTypes.func.isRequired,
  onClickPageDelete: propTypes.func.isRequired,
  onClickPageArchive: propTypes.func.isRequired,
  onClickPageReinstate: propTypes.func.isRequired,
  onClickResetAll: propTypes.func.isRequired,
  onClickCompleteAll: propTypes.func.isRequired,
  onClosePageEdit: propTypes.func.isRequired,
  activePageTitle: propTypes.string,
};

PageEditDialogView.defaultProps = {
  isFullScreen: false,
  isPageEditOpen: false,
  isPageArchived: false,
  activePageTitle: '',
};

export default PageEditDialogView;
