import React from 'react';
import propTypes from 'prop-types';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { MoreHoriz } from '@mui/icons-material';

import useStyles from './styles';
import { ListItemButton } from '@mui/material';

const getIcon = (id) => {
  if (id === 1) {
    return '/screw-half.png';
  } if (id === 2) {
    return '/screw-done.png';
  }
  return '/screw-todo.png';
};

function PageItemView(props) {
  const {
    id,
    state,
    content,
    onClickPageItem,
    onClickPageItemEdit,
  } = props;

  const classes = useStyles();

  return (
    <ListItemButton
      role={undefined}
      dense
      onClick={onClickPageItem}
      onContextMenu={onClickPageItem}
      className={classes.listItem}
    >
      <ListItemAvatar>
        <Avatar
          alt="To Do"
          src={getIcon(state)}
          sx={{ width: 24, height: 24 }}
          // className={classes.small}
        />
      </ListItemAvatar>
      <Tooltip title={`Created ${new Date(id).toLocaleString()}`} placement="bottom-end">
        <ListItemText
          primary={state === 2 ? (<strike>{content}</strike>) : content}
        />
      </Tooltip>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          className="drag-handle"
          aria-label="comments"
          onClick={onClickPageItemEdit}
        >
          <MoreHoriz />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  );
}

PageItemView.propTypes = {
  id: propTypes.number.isRequired,
  state: propTypes.number.isRequired,
  content: propTypes.string.isRequired,
  onClickPageItem: propTypes.func.isRequired,
  onClickPageItemEdit: propTypes.func.isRequired,
};

export default PageItemView;
