import { makeStyles } from '@mui/styles';
import { lightGreen } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  card: {
    textAlign: 'left',
    minWidth: 275,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    '&:hover': {
      backgroundColor: lightGreen[700],
    },
  },
  section: {
    padding: theme.spacing(1),
  },
}));

export default useStyles;
