import { makeStyles } from '@mui/styles';
import { lightGreen, blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  sectionTop: {
    marginBottom: theme.spacing(2),
    textAlign: 'left',
  },
  sectionMiddle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  sectionBottom: {
    marginTop: theme.spacing(2),
    textAlign: 'left',
  },
  submitButton: {
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    '&:hover': {
      backgroundColor: lightGreen[700],
    },
  },
  blueButton: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
}));

export default useStyles;
