import { useContext } from 'react';

import EventListenerContext from './context';

import { eventAdd } from './actions';

export const SEVERITY_INFO = 'info';
export const SEVERITY_WARNING = 'warning';
export const SEVERITY_ERROR = 'error';
export const SEVERITY_SUCCESS = 'success';

export const useEventEmitter = () => {
  const dispatch = useContext(EventListenerContext);

  return (severity, message) => {
    dispatch(eventAdd(severity, message));
  };
};
