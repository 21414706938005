import { EVENT_ADD, EVENT_NEXT, TOAST_CLOSE } from './actions';

export const initialState = {
  loadedEvent: null,
  events: [],
  isOpen: false,
};

const eventListenerReducer = (state, action) => {
  switch (action.type) {
    case EVENT_ADD:
      return (!state.loadedEvent)
        ? {
          ...state,
          loadedEvent: {
            severity: action.severity,
            message: action.message,
          },
          events: [],
          isOpen: true,
        }
        : {
          ...state,
          events: [
            ...state.events.map((event) => ({ ...event })),
            {
              severity: action.severity,
              message: action.message,
            },
          ],
        };

    case EVENT_NEXT:
      return (() => {
        const [nextEvent, ...rest] = state.events.map((event) => ({ ...event }));

        return ({
          ...state,
          loadedEvent: nextEvent || null,
          events: rest || [],
          isOpen: !!nextEvent,
        });
      })();

    case TOAST_CLOSE:
      return {
        ...state,
        loadedEvent: { ...state.loadedEvent },
        events: [
          ...state.events.map((event) => ({ ...event })),
        ],
        isOpen: false,
      };

    default:
      return state;
  }
};

export default eventListenerReducer;
