export const DIALOG_PAGE_EDIT_OPEN = "DIALOG_PAGE_EDIT_OPEN";
export const DIALOG_PAGE_EDIT_CLOSE = "DIALOG_PAGE_EDIT_CLOSE";

export const dialogPageEditOpen = (pageId, pageTitle) => ({
  type: DIALOG_PAGE_EDIT_OPEN,
  pageId,
  pageTitle,
});
export const dialogPageEditClose = () => ({ type: DIALOG_PAGE_EDIT_CLOSE });

export const DIALOG_PAGE_ITEM_EDIT_OPEN = "DIALOG_PAGE_ITEM_EDIT_OPEN";
export const DIALOG_PAGE_ITEM_EDIT_CLOSE = "DIALOG_PAGE_ITEM_EDIT_CLOSE";

export const dialogPageItemEditOpen = (pageId, itemId, itemContent) => ({
  type: DIALOG_PAGE_ITEM_EDIT_OPEN,
  pageId,
  itemId,
  itemContent,
});

export const RESET_STATE = "RESET_STATE";

export const resetState = () => ({ type: RESET_STATE });

export const dialogPageItemEditClose = () => ({
  type: DIALOG_PAGE_ITEM_EDIT_CLOSE,
});
