import React from 'react';
import propTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DialogTitle } from '@mui/material';

import Loadable from '../../../../Loadable';

import useStyles from './styles';

function DeleteAccountDialogView(props) {
  const {
    isFullScreen,
    isDeleteAccountDialogOpen,
    isDeleteAccountDialogLoading,
    onCloseDeleteAccountDialog,
    onClickDeleteAccountConfirm,
  } = props;

  const classes = useStyles();

  return (

    <Dialog
      open={isDeleteAccountDialogOpen}
      onClose={onCloseDeleteAccountDialog}
      aria-labelledby="form-dialog-title"
      fullScreen={isFullScreen}
    >
      <DialogTitle>
        Confirm Delete Account
      </DialogTitle>
      <Loadable isLoading={isDeleteAccountDialogLoading}>
        <DialogContent>
          <DialogContentText>
            If you delete your account all of your pages, items, and account details will
            be deleted and cannot be recovered.
          </DialogContentText>
          <DialogContentText>
            If you are deleting your account because you are not happy with Screw Notes or
            something is not working properly, please contact support and
            we will try our best to help you first.
          </DialogContentText>
        </DialogContent>
      </Loadable>
      <DialogActions>
        <Button
          className={classes.blueButton}
          variant="contained"
          size="large"
          component="a"
          target="_blank"
          href="mailto:support@screwnotes.com"
          startIcon={<FontAwesomeIcon icon={faEnvelope} />}
        >
          Contact Support
        </Button>
        <Button
          className={classes.errorButton}
          type="submit"
          variant="contained"
          size="large"
          onClick={onClickDeleteAccountConfirm}
          startIcon={<FontAwesomeIcon icon={faTrash} />}
        >
          Delete Your Account
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteAccountDialogView.propTypes = {
  isFullScreen: propTypes.bool,
  isDeleteAccountDialogOpen: propTypes.bool,
  isDeleteAccountDialogLoading: propTypes.bool,
  onCloseDeleteAccountDialog: propTypes.func.isRequired,
  onClickDeleteAccountConfirm: propTypes.func.isRequired,
};

DeleteAccountDialogView.defaultProps = {
  isFullScreen: false,
  isDeleteAccountDialogOpen: false,
  isDeleteAccountDialogLoading: false,
};

export default DeleteAccountDialogView;
