import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import LoginView from './LoginView';

import LoginContext from './context';
import loginReducer, { initialState } from './reducer';
import { useSafeDispatch } from '../../utils';

import { useAccountStatus } from '../Account';

import {
  dialogSignInOpen,
  dialogSignUpOpen,
} from './actions';

import { useRedirect } from '../../utils/hooks';

function LoginContainer(props) {
  const { isFullScreen } = props;

  const [state, dispatch] = useReducer(loginReducer, initialState);

  const safeDispatch = useSafeDispatch(dispatch);

  const accountStatus = useAccountStatus();
  const redirect = useRedirect();

  const handleOpenSignIn = () => safeDispatch(dialogSignInOpen());

  const handleOpenSignOut = () => safeDispatch(dialogSignUpOpen());

  const handleRedirect = () => redirect();

  return (
    <LoginContext.Provider value={safeDispatch}>
      <LoginView
        isFullScreen={isFullScreen}
        isSignInLoading={state.isSignInLoading || accountStatus.isLoading}
        isSignInOpen={state.isSignInOpen}
        isSignUpOpen={state.isSignUpOpen}
        isSignUpLoading={state.isSignUpLoading || accountStatus.isLoading}
        isPasswordResetOpen={state.isPasswordResetOpen}
        isPasswordResetLoading={state.isPasswordResetLoading || accountStatus.isLoading}
        onOpenSignIn={handleOpenSignIn}
        onOpenSignUp={handleOpenSignOut}
        onRedirect={handleRedirect}
      />
    </LoginContext.Provider>
  );
}

LoginContainer.propTypes = {
  isFullScreen: PropTypes.bool,
};

LoginContainer.defaultProps = {
  isFullScreen: false,
};

export default LoginContainer;
