import React from 'react';
import propTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { DialogTitle } from '@mui/material';

import ExternalIdpList from '../ExternalIdpList';
import Loadable from '../../Loadable';

import useStyles from './styles';

function SignInDialogView(props) {
  const {
    isFullScreen,
    isSignInLoading,
    isSignInOpen,
    signInErrorMessage,
    onClickSignIn,
    onClose,
    onEmailChange,
    onPasswordChange,
    onPasswordForgotten,
    onError,
    onRedirect,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={isSignInOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={isFullScreen}
    >
      <DialogTitle>
        Sign In To Screw Notes
      </DialogTitle>
      <DialogContent>
        <Loadable isLoading={isSignInLoading}>
          <form
            onSubmit={onClickSignIn}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  fullWidth
                  required
                  id="loginEmail"
                  onChange={onEmailChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  type="password"
                  required
                  fullWidth
                  id="loginPassword"
                  onChange={onPasswordChange}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button
                  className={classes.submitButton}
                  type="submit"
                  variant="contained"
                  size="large"
                  startIcon={<FontAwesomeIcon icon={faEnvelope} />}
                >
                  Sign In With Email
                </Button>
              </Grid>
              {signInErrorMessage && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    <Typography>
                      {signInErrorMessage}
                    </Typography>
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <ExternalIdpList
                  onError={onError}
                  onRedirect={onRedirect}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button
                  className={classes.blueGreyButton}
                  variant="contained"
                  size="large"
                  onClick={onPasswordForgotten}
                >
                  Forgot Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </Loadable>
      </DialogContent>
    </Dialog>
  );
}

SignInDialogView.propTypes = {
  isFullScreen: propTypes.bool,
  isSignInLoading: propTypes.bool,
  isSignInOpen: propTypes.bool,
  signInErrorMessage: propTypes.string,
  onClickSignIn: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  onEmailChange: propTypes.func.isRequired,
  onPasswordChange: propTypes.func.isRequired,
  onPasswordForgotten: propTypes.func.isRequired,
  onError: propTypes.func.isRequired,
  onRedirect: propTypes.func.isRequired,
};

SignInDialogView.defaultProps = {
  isFullScreen: false,
  isSignInLoading: false,
  isSignInOpen: false,
  signInErrorMessage: '',
};

export default SignInDialogView;
