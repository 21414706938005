import React from 'react';
import propTypes from 'prop-types';

import Container from '@mui/material/Container';

import WhatsNewView from './WhatsNewView';
import PageList from '../PageList';
import useStyles from './styles';

function HomeView(props) {
  const {
    isFullScreen,
    showWhatsNew,
    onCloseWhatsNew,
    notMobile,
  } = props;
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="sm" className={notMobile ? undefined : classes.noPadding}>
        <PageList
          isFullScreen={isFullScreen}
          notMobile={notMobile}
        />
      </Container>
      <WhatsNewView
        showWhatsNew={showWhatsNew}
        onCloseWhatsNew={onCloseWhatsNew}
        isFullScreen={isFullScreen}
      />
    </>
  );
}

HomeView.propTypes = {
  isFullScreen: propTypes.bool,
  showWhatsNew: propTypes.bool,
  notMobile: propTypes.bool,
  onCloseWhatsNew: propTypes.func.isRequired,
};

HomeView.defaultProps = {
  isFullScreen: false,
  showWhatsNew: false,
  notMobile: false,
};

export default HomeView;
