import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 275,
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    paddingBottom: '0px',
  },
}));

export default useStyles;
