import { useCallback, useState, useEffect } from "react";

import { useSelector } from "react-redux";

import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
} from "react-redux-firebase";

import { useAccountStatus, useSubscriptionStatus } from "../Account";
import { useExecutor } from "../../utils";

export const usePages = () => {
  const { userId } = useAccountStatus();

  useFirestoreConnect([
    {
      collection: "pages",
      orderBy: ["createdAt", "desc"],
      where: [
        ["creatorId", "==", userId || "none"],
        ["isArchived", "==", false],
      ],
      storeAs: "myPages",
    },
  ]);

  const pages = useSelector((state) => state.firestore.ordered.myPages);

  return pages;
};

export const useArchivedPages = () => {
  const { userId } = useAccountStatus();

  useFirestoreConnect([
    {
      collection: "pages",
      orderBy: ["createdAt", "desc"],
      where: [
        ["creatorId", "==", userId || "none"],
        ["isArchived", "==", true],
      ],
      storeAs: "myArchivedPages",
    },
  ]);

  const pages = useSelector((state) => state.firestore.ordered.myArchivedPages);

  return pages;
};

export const useArePagesLoading = () => {
  const pages = usePages();

  const [arePagesLoading, setArePagesLoading] = useState(!isLoaded(pages));

  useEffect(
    () => setArePagesLoading(!isLoaded(pages)),
    [setArePagesLoading, pages]
  );

  return arePagesLoading;
};

export const usePageBulkRestore = (onError, onSuccess) => {
  const firestore = useFirestore();
  const pages = usePages();

  const [currentPageId, setCurrentPageId] = useState("");
  const memoisedFunction = useCallback(async () => {
    const page = pages.find((pg) => pg.id === currentPageId);

    if (!page) {
      if (onError) {
        await onError(Error("Page not found"));
      }

      return;
    }

    const newItems = page.items.map((item) => ({
      ...item,
      state: 0,
    }));

    try {
      await firestore.update(`pages/${page.id}`, {
        items: newItems,
        updatedAt: Date.now(),
      });
    } catch (error) {
      if (onError) {
        await onError(error);
      }

      return;
    }

    if (onSuccess) {
      await onSuccess();
    }
  }, [firestore, pages, currentPageId, onError, onSuccess]);

  const execute = useExecutor(memoisedFunction);

  return (pageId) => {
    setCurrentPageId(pageId);

    execute();
  };
};

export const usePageBulkComplete = (onError, onSuccess) => {
  const firestore = useFirestore();
  const pages = usePages();

  const [currentPageId, setCurrentPageId] = useState("");
  const memoisedFunction = useCallback(async () => {
    const page = pages.find((pg) => pg.id === currentPageId);

    if (!page) {
      if (onError) {
        await onError(Error("Page not found"));
      }

      return;
    }

    const newItems = page.items.map((item) => ({
      ...item,
      state: 2,
    }));

    try {
      await firestore.update(`pages/${page.id}`, {
        items: newItems,
        updatedAt: Date.now(),
      });
    } catch (error) {
      if (onError) {
        await onError(error);
      }

      return;
    }

    if (onSuccess) {
      await onSuccess();
    }
  }, [firestore, pages, currentPageId, onError, onSuccess]);

  const execute = useExecutor(memoisedFunction);

  return (pageId) => {
    setCurrentPageId(pageId);

    execute();
  };
};

export const usePageItemNextState = (onError, onSuccess) => {
  const firestore = useFirestore();
  const pages = usePages();
  let completionMode = localStorage.getItem("completionMode");
  if (!completionMode) {
    completionMode = "leftClick"; // leftClick is default
  }

  const [currentPageId, setCurrentPageId] = useState("");
  const [currentItemId, setCurrentItemId] = useState("");
  const [currentClickType, setCurrentClickType] = useState("");

  const memoisedFunction = useCallback(async () => {
    const page = pages.find((pg) => pg.id === currentPageId);

    if (!page) {
      if (onError) {
        await onError(Error("Page not found"));
      }

      return;
    }

    const newItems = page.items.map((item) => {
      const currentState = item.state;
      const newItem = { ...item };

      if (item.id === currentItemId) {
        if (completionMode === "leftClick") {
          if (currentClickType === "click") {
            if (currentState === 0) {
              newItem.state = 2;
            }
            if (currentState === 1) {
              newItem.state = 2;
            }
            if (currentState === 2) {
              newItem.state = 0;
            }
          } else if (currentClickType === "contextmenu") {
            if (currentState === 0) {
              newItem.state = 1;
            }
            if (currentState === 1) {
              newItem.state = 2;
            }
            if (currentState === 2) {
              newItem.state = 0;
            }
          }
        } else if (currentClickType === "contextmenu") {
          if (currentState === 0) {
            newItem.state = 2;
          }
          if (currentState === 1) {
            newItem.state = 2;
          }
          if (currentState === 2) {
            newItem.state = 0;
          }
        } else if (currentClickType === "click") {
          if (currentState === 0) {
            newItem.state = 1;
          }
          if (currentState === 1) {
            newItem.state = 2;
          }
          if (currentState === 2) {
            newItem.state = 0;
          }
        }
      }

      return newItem;
    });

    try {
      await firestore.update(`pages/${page.id}`, {
        items: newItems,
        updatedAt: Date.now(),
      });
    } catch (error) {
      if (onError) {
        await onError(error);
      }

      return;
    }

    if (onSuccess) {
      await onSuccess();
    }
  }, [
    firestore,
    pages,
    currentPageId,
    currentItemId,
    currentClickType,
    onError,
    onSuccess,
  ]);

  const execute = useExecutor(memoisedFunction);

  return (pageId, itemId, e) => {
    setCurrentPageId(pageId);
    setCurrentItemId(itemId);
    setCurrentClickType(e.type);

    execute();
  };
};

export const useAddPageItem = (onError, onSuccess) => {
  const firestore = useFirestore();
  const pages = usePages();

  const [currentPageId, setCurrentPageId] = useState("");
  const [currentContent, setCurrentContent] = useState("");

  const memoisedFunction = useCallback(async () => {
    const page = pages.find((pg) => pg.id === currentPageId);

    if (!page) {
      if (onError) {
        await onError(Error("Page not found"));
      }

      return;
    }

    const newPageItems = page.items.map((item) => ({ ...item }));

    const newPageItem = {
      id: Date.now(),
      state: 0,
      content: currentContent,
    };

    newPageItems.push(newPageItem);

    try {
      await firestore.update(`pages/${page.id}`, {
        items: newPageItems,
        updatedAt: Date.now(),
      });
    } catch (error) {
      if (onError) {
        await onError(error);
      }

      return;
    }

    if (onSuccess) {
      await onSuccess();
    }
  }, [firestore, pages, currentPageId, currentContent, onError, onSuccess]);

  const execute = useExecutor(memoisedFunction);

  return (pageId, content) => {
    setCurrentPageId(pageId);
    setCurrentContent(content);

    execute();
  };
};

export const useDeletePageItem = (onError, onSuccess) => {
  const firestore = useFirestore();
  const pages = usePages();

  const [currentPageId, setCurrentPageId] = useState("");
  const [currentItemId, setCurrentItemId] = useState("");

  const memoisedFunction = useCallback(async () => {
    const page = pages.find((pg) => String(pg.id) === String(currentPageId));

    if (!page) {
      if (onError) {
        await onError(Error("Page not found"));
      }

      return;
    }

    const newItems = page.items
      .filter((item) => String(item.id) !== String(currentItemId))
      .map((item) => ({ ...item }));

    try {
      await firestore.update(`pages/${currentPageId}`, {
        items: newItems,
        updatedAt: Date.now(),
      });
    } catch (error) {
      if (onError) {
        await onError(error);
      }

      return;
    }

    if (onSuccess) {
      await onSuccess();
    }
  }, [firestore, pages, currentPageId, currentItemId, onError, onSuccess]);

  const execute = useExecutor(memoisedFunction);

  return (pageId, itemId) => {
    setCurrentPageId(pageId);
    setCurrentItemId(itemId);

    execute();
  };
};

export const useUpdatePageItem = (onError, onSuccess) => {
  const firestore = useFirestore();
  const pages = usePages();

  const [currentPageId, setCurrentPageId] = useState("");
  const [currentItemId, setCurrentItemId] = useState("");
  const [currentUpdatedContent, setCurrentUpdatedContent] = useState("");

  const memoisedFunction = useCallback(async () => {
    const page = pages.find((pg) => String(pg.id) === String(currentPageId));

    if (!page) {
      if (onError) {
        await onError(Error("Page not found"));
      }

      return;
    }

    const newItems = page.items.map((item) => {
      // cast them to string to prevent type mismatches
      if (String(item.id) === String(currentItemId)) {
        return {
          ...item,
          content: currentUpdatedContent,
          updatedAt: Date.now(),
        };
      }

      return { ...item };
    });

    try {
      await firestore.update(`pages/${currentPageId}`, {
        items: newItems,
        updatedAt: Date.now(),
      });
    } catch (error) {
      if (onError) {
        await onError(error);
      }

      return;
    }

    if (onSuccess) {
      await onSuccess();
    }
  }, [
    pages,
    firestore,
    currentPageId,
    currentItemId,
    currentUpdatedContent,
    onSuccess,
    onError,
  ]);

  const execute = useExecutor(memoisedFunction);

  return (pageId, itemId, updatedContent) => {
    setCurrentPageId(pageId);
    setCurrentItemId(itemId);
    setCurrentUpdatedContent(updatedContent);

    execute();
  };
};

export const useUpdatePageItems = (onError, onSuccess) => {
  const firestore = useFirestore();
  const pages = usePages();

  const [currentPageId, setCurrentPageId] = useState("");
  const [currentUpdatedItems, setCurrentUpdatedItems] = useState([]);

  const memoisedFunction = useCallback(async () => {
    const page = pages.find((pg) => pg.id === currentPageId);

    if (!page) {
      if (onError) {
        await onError(Error("Page not found"));
      }

      return;
    }
    try {
      await firestore.update(`pages/${page.id}`, {
        items: currentUpdatedItems,
        updatedAt: Date.now(),
      });
    } catch (error) {
      if (onError) {
        onError(error);
      }

      return;
    }

    if (onSuccess) {
      onSuccess();
    }
  }, [
    firestore,
    pages,
    currentPageId,
    currentUpdatedItems,
    onError,
    onSuccess,
  ]);

  const execute = useExecutor(memoisedFunction);

  return (pageId, updatedItems) => {
    setCurrentPageId(pageId);
    setCurrentUpdatedItems(updatedItems);

    execute();
  };
};

export const useUpdatePage = (onError, onSuccess) => {
  const firestore = useFirestore();
  const pages = usePages();

  const [currentPageId, setCurrentPageId] = useState("");
  const [currentUpdatedTitle, setCurrentUpdatedTitle] = useState("");

  const memoisedFunction = useCallback(async () => {
    const page = pages.find((pg) => pg.id === currentPageId);

    if (!page) {
      if (onError) {
        await onError(Error("Page not found"));
      }

      return;
    }
    try {
      await firestore.update(`pages/${page.id}`, {
        title: currentUpdatedTitle,
        updatedAt: Date.now(),
      });
    } catch (error) {
      if (onError) {
        onError(error);
      }

      return;
    }

    if (onSuccess) {
      onSuccess();
    }
  }, [
    firestore,
    pages,
    currentPageId,
    currentUpdatedTitle,
    onError,
    onSuccess,
  ]);

  const execute = useExecutor(memoisedFunction);

  return (pageId, updatedTitle) => {
    setCurrentPageId(pageId);
    setCurrentUpdatedTitle(updatedTitle);

    execute();
  };
};

export const useArchivePage = (onError, onSuccess) => {
  const firestore = useFirestore();
  const pages = usePages();

  const [currentPageId, setCurrentPageId] = useState("");

  const memoisedFunction = useCallback(async () => {
    const page = pages.find((pg) => pg.id === currentPageId);

    if (!page) {
      if (onError) {
        await onError(Error("Page not found"));
      }

      return;
    }

    try {
      await firestore.update(`pages/${currentPageId}`, {
        isArchived: true,
        updatedAt: Date.now(),
      });
    } catch (error) {
      if (onError) {
        await onError(error);
      }

      return;
    }

    if (onSuccess) {
      await onSuccess();
    }
  }, [firestore, pages, currentPageId, onError, onSuccess]);
  const execute = useExecutor(memoisedFunction);

  return (pageId) => {
    setCurrentPageId(pageId);

    execute();
  };
};

export const useDeletePage = (onError, onSuccess) => {
  const firestore = useFirestore();
  const pages = useArchivedPages();

  const [currentPageId, setCurrentPageId] = useState("");

  const memoisedFunction = useCallback(async () => {
    const page = pages.find((pg) => pg.id === currentPageId);

    if (!page) {
      if (onError) {
        await onError(Error("Page not found"));
      }

      return;
    }

    try {
      await firestore.delete(`pages/${currentPageId}`);
    } catch (error) {
      if (onError) {
        await onError(error);
      }

      return;
    }

    if (onSuccess) {
      await onSuccess();
    }
  }, [firestore, pages, currentPageId, onError, onSuccess]);

  const execute = useExecutor(memoisedFunction);

  return (pageId) => {
    setCurrentPageId(pageId);

    execute();
  };
};

export const useReinstatePage = (onError, onSuccess) => {
  const firestore = useFirestore();
  const pages = useArchivedPages();

  const [currentPageId, setCurrentPageId] = useState("");

  const memoisedFunction = useCallback(async () => {
    const page = pages.find((pg) => pg.id === currentPageId);

    if (!page) {
      if (onError) {
        await onError(Error("Page not found"));
      }

      return;
    }

    try {
      await firestore.update(`pages/${currentPageId}`, {
        isArchived: false,
        updatedAt: Date.now(),
      });
    } catch (error) {
      if (onError) {
        await onError(error);
      }

      return;
    }

    if (onSuccess) {
      await onSuccess();
    }
  }, [firestore, pages, currentPageId, onError, onSuccess]);

  const execute = useExecutor(memoisedFunction);

  return (pageId) => {
    setCurrentPageId(pageId);

    execute();
  };
};

export const useAddPage = (onError, onSuccess) => {
  const firestore = useFirestore();
  const { userId } = useAccountStatus();
  const hasSubscription = useSubscriptionStatus();
  const pages = usePages();

  const memoisedFunction = useCallback(async () => {
    if (!hasSubscription && (pages.length || 0) === 3) {
      if (onError) {
        await onError(
          Error("Pro subscription required to use more than three pages")
        );
      }

      return;
    }
    try {
      await firestore.add("pages", {
        items: [],
        title: "New Page",
        creatorId: userId,
        createdAt: Date.now(),
        isArchived: false,
      });
    } catch (error) {
      if (onError) {
        await onError(error);
      }

      return;
    }

    if (onSuccess) {
      onSuccess();
    }
  }, [firestore, userId, hasSubscription, pages, onError, onSuccess]);

  const execute = useExecutor(memoisedFunction);

  return () => execute();
};
