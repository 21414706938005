import React from 'react';
import propTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import SignInDialog from './SignInDialog';
import SignUpDialog from './SignUpDialog';
import PasswordResetDialog from './PasswordResetDialog';

import useStyles from './styles';

function LoginView(props) {
  const {
    isFullScreen,
    isSignInLoading,
    isSignInOpen,
    isSignUpOpen,
    isSignUpLoading,
    isPasswordResetOpen,
    isPasswordResetLoading,
    onOpenSignIn,
    onOpenSignUp,
    onRedirect,
  } = props;

  const classes = useStyles();

  return (
    <>
      <Card className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert>
              <Typography>
                Please sign in to use Screw Notes, or sign up if you
                don&apos;t have an account.
              </Typography>
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroup size="large" aria-label="authentication buttons" fullWidth>
              <Button
                className={classes.submitButton}
                onClick={onOpenSignIn}
                startIcon={<FontAwesomeIcon icon={faSignInAlt} />}
              >
                Sign In
              </Button>
              <Button
                className={classes.blueButton}
                onClick={onOpenSignUp}
                startIcon={<FontAwesomeIcon icon={faUserPlus} />}
              >
                Sign Up
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              <Typography>
                By signing up, you are agreeing to our
                {' '}
                <a href="https://firebasestorage.googleapis.com/v0/b/screwnotes.appspot.com/o/Terms%20and%20Conditions.pdf?alt=media&token=b3ecc77d-92ba-4667-a624-5331c30310a3" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                {' '}
                and
                {' '}
                <a href="https://firebasestorage.googleapis.com/v0/b/screwnotes.appspot.com/o/Privacy%20Policy.pdf?alt=media&token=9452d4c4-0e32-42aa-a38c-4946430655ff" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                . Signing up will also
                opt you in for email notifications from us. Don&apos;t worry, we
                won&apos;t spam you with marketing junk, and
                you can unsubscribe at any time.
              </Typography>
            </Alert>
          </Grid>
        </Grid>
      </Card>
      <SignInDialog
        isFullScreen={isFullScreen}
        isSignInLoading={isSignInLoading}
        isSignInOpen={isSignInOpen}
        onRedirect={onRedirect}
      />
      <SignUpDialog
        isFullScreen={isFullScreen}
        isSignUpOpen={isSignUpOpen}
        isSignUpLoading={isSignUpLoading}
        onRedirect={onRedirect}
      />
      <PasswordResetDialog
        isPasswordResetOpen={isPasswordResetOpen}
        isPasswordResetLoading={isPasswordResetLoading}
      />
    </>
  );
}

LoginView.propTypes = {
  isFullScreen: propTypes.bool,
  isSignInLoading: propTypes.bool,
  isSignInOpen: propTypes.bool,
  isSignUpOpen: propTypes.bool,
  isSignUpLoading: propTypes.bool,
  isPasswordResetOpen: propTypes.bool,
  isPasswordResetLoading: propTypes.bool,
  onOpenSignIn: propTypes.func.isRequired,
  onOpenSignUp: propTypes.func.isRequired,
  onRedirect: propTypes.func.isRequired,
};

LoginView.defaultProps = {
  isFullScreen: false,
  isSignInLoading: false,
  isSignInOpen: false,
  isSignUpOpen: false,
  isSignUpLoading: false,
  isPasswordResetOpen: false,
  isPasswordResetLoading: false,
};

export default LoginView;
