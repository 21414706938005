import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import propTypes from 'prop-types';

import { dialogPageEditClose } from '../actions';
import PageListContext from '../context';

import {
  useArchivePage,
  useDeletePage,
  useReinstatePage,
  useUpdatePage,
  usePageBulkComplete,
  usePageBulkRestore,
} from '../hooks';

import { useEventEmitter, SEVERITY_ERROR } from '../../EventListener';
import PageEditDialogView from './PageEditDialogView';

function PageEditDialogContainer(props) {
  const {
    isFullScreen,
    isPageEditOpen,
    isPageArchived,
    activePageId,
    activePageTitle,
  } = props;

  const [currentPageTitle, setCurrentPageTitle] = useState(activePageTitle);

  useEffect(() => setCurrentPageTitle(activePageTitle), [activePageTitle]);

  const dispatch = useContext(PageListContext);
  const emitEvent = useEventEmitter();

  const archivePage = useArchivePage(
    (error) => emitEvent(
      SEVERITY_ERROR,
      error.message,
    ),
  );

  const deletePage = useDeletePage(
    (error) => emitEvent(
      SEVERITY_ERROR,
      error.message,
    ),
  );

  const updatePage = useUpdatePage(
    (error) => emitEvent(
      SEVERITY_ERROR,
      error.message,
    ),
  );

  const reinstatePage = useReinstatePage(
    (error) => emitEvent(
      SEVERITY_ERROR,
      error.message,
    ),
  );

  const pageBulkComplete = usePageBulkComplete(
    (error) => emitEvent(
      SEVERITY_ERROR,
      error.message,
    ),
  );

  const pageBulkRestore = usePageBulkRestore(
    (error) => emitEvent(
      SEVERITY_ERROR,
      error.message,
    ),
  );

  const handleChangePageTitle = (e) => setCurrentPageTitle(e.target.value);

  const handleClosePageEdit = useCallback(
    () => dispatch(dialogPageEditClose()),
    [dispatch],
  );

  const handleClickPageArchive = useCallback((e) => {
    e.preventDefault();

    archivePage(activePageId);

    handleClosePageEdit();
  }, [activePageId]);

  const handleClickPageDelete = useCallback((e) => {
    e.preventDefault();

    deletePage(activePageId);

    handleClosePageEdit();
  }, [activePageId]);

  const handleClickPageReinstate = useCallback((e) => {
    e.preventDefault();

    reinstatePage(activePageId);

    handleClosePageEdit();
  }, [activePageId]);

  const handleClickPageUpdate = useCallback((e) => {
    e.preventDefault();

    updatePage(
      activePageId,
      currentPageTitle,
    );

    handleClosePageEdit();
  }, [activePageId, currentPageTitle]);

  const handleClickCompleteAll = useCallback((e) => {
    e.preventDefault();

    pageBulkComplete(activePageId);

    handleClosePageEdit();
  }, [activePageId]);

  const handleClickResetAll = useCallback((e) => {
    e.preventDefault();

    pageBulkRestore(activePageId);

    handleClosePageEdit();
  }, [activePageId]);

  return (
    <PageEditDialogView
      isFullScreen={isFullScreen}
      isPageEditOpen={isPageEditOpen}
      isPageArchived={isPageArchived}
      onChangePageTitle={handleChangePageTitle}
      onClickPageUpdate={handleClickPageUpdate}
      onClickPageDelete={handleClickPageDelete}
      onClickPageArchive={handleClickPageArchive}
      onClickCompleteAll={handleClickCompleteAll}
      onClickResetAll={handleClickResetAll}
      onClickPageReinstate={handleClickPageReinstate}
      onClosePageEdit={handleClosePageEdit}
      activePageTitle={currentPageTitle}
    />
  );
}

PageEditDialogContainer.propTypes = {
  isFullScreen: propTypes.bool,
  isPageEditOpen: propTypes.bool,
  isPageArchived: propTypes.bool,
  activePageId: propTypes.string,
  activePageTitle: propTypes.string,
};

PageEditDialogContainer.defaultProps = {
  isFullScreen: false,
  isPageEditOpen: false,
  isPageArchived: false,
  activePageId: '',
  activePageTitle: '',
};

export default PageEditDialogContainer;
