const ENV_DEV = 'DEV';
const ENV_PROD = 'PROD';

export const currentAppVersion = 20200801; // update this whenever you update the release notes

export const getEnv = () => (process.env.REACT_APP_ENV);

// this hostname check is specifically for the deployed dev site, as it thinks it's in
// prod otherwise
export const isDev = () => (getEnv() === ENV_DEV || window.location.hostname === 'screwnotes-dev.web.app');

export const isProd = () => (getEnv() === ENV_PROD);
