/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import propTypes from "prop-types";

import { Route, Redirect } from "react-router-dom";

import RouteDelay from "./RouteDelay";

import { useAccountStatus } from "../Account";

const ProtectedRouteContainer = (props) => {
  const { children, ...rest } = props;

  const accountStatus = useAccountStatus();

  const { isLoading, isLoggedIn, isEmailProvided, isEmailVerified } =
    accountStatus;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isLoading) {
          return <RouteDelay />;
        }
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location || "/" },
              }}
            />
          );
        }

        if (
          (!isEmailProvided || !isEmailVerified) &&
          location.pathname !== "/verify"
        ) {
          return (
            <Redirect
              to={{
                pathname: "/verify",
                state: { from: location || "/" },
              }}
            />
          );
        }

        if (isLoggedIn && location.state && location.state.from === "/login") {
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }

        return children;
      }}
    />
  );
};

ProtectedRouteContainer.propTypes = {
  children: propTypes.node.isRequired,
};

export default ProtectedRouteContainer;
