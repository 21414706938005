import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useComponentStatusRef = (componentStatusRef) => {
  let fallbackComponentStatus = useRef(true);

  if (componentStatusRef !== undefined) {
    fallbackComponentStatus = null;
  }

  const componentStatus = componentStatusRef || fallbackComponentStatus;

  useEffect(() => {
    if (componentStatusRef === undefined) {
      return () => { componentStatus.current = false; };
    }
    return () => {};
  }, []);

  return componentStatus;
};

export const useSafeDispatch = (dispatch, componentStatusRef) => {
  const componentStatus = useComponentStatusRef(componentStatusRef);

  return (action) => {
    if (componentStatus.current === true) {
      dispatch(action);
    }
  };
};

export const useSafeState = (initialState, componentStatusRef) => {
  let fallbackComponentStatus = useComponentStatusRef();

  if (componentStatusRef !== undefined) {
    fallbackComponentStatus = null;
  }

  const componentStatus = componentStatusRef || fallbackComponentStatus;

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (componentStatusRef === undefined) {
      return () => { componentStatus.current = false; };
    }
    return () => {};
  }, []);

  const safeSetState = (newState) => {
    if (componentStatus.current === true) {
      setState(newState);
    }
  };

  return [state, safeSetState];
};

export const useExecutor = (func) => {
  const [executionCounter, setExecutionCounter] = useSafeState(0);

  const previousCounterRef = useRef(0);

  useEffect(() => {
    if (executionCounter !== previousCounterRef.current) {
      previousCounterRef.current = executionCounter;

      (async () => {
        await func();
      })();
    }
  }, [
    func,
    executionCounter,
    previousCounterRef,
  ]);

  const execute = () => {
    const newCount = (executionCounter !== Number.MAX_SAFE_INTEGER)
      ? executionCounter + 1
      : 0;

    setExecutionCounter(newCount);
  };

  return execute;
};

export const useRedirect = () => {
  const history = useHistory();
  const location = useLocation();

  const memoisedFunc = useCallback(
    () => {
      if (!location.state || !location.state.from || location.state.from === '/login') {
        history.replace('/');
      } else {
        history.replace(location.state.from);
      }
    },
    [history, location],
  );

  const execute = useExecutor(memoisedFunc);

  return () => execute();
};

export const useDisplayStatus = () => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(
    theme.breakpoints.down('xs'),
  );

  const displayStatus = useMemo(() => ({ isFullScreen }), [isFullScreen]);

  return displayStatus;
};
