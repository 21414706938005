import React from 'react';
import propTypes from 'prop-types';

import HomeView from './HomeView';
import { currentAppVersion } from '../../config';

function HomeContainer(props) {
  const {
    isFullScreen,
    notMobile,
  } = props;
  const [showWhatsNew, setShowWhatsNew] = React.useState(false);

  React.useEffect(() => {
    const dismissedVersion = localStorage.getItem('dismissedVersion');
    if (dismissedVersion && Number(dismissedVersion) < currentAppVersion) {
      setShowWhatsNew(true);
    } else if (!dismissedVersion) {
      setShowWhatsNew(true);
    }
  }, []);

  const handleCloseWhatsNew = () => {
    // update the localStorage with the latest version
    localStorage.setItem('dismissedVersion', currentAppVersion.toString());
    setShowWhatsNew(false);
  };

  // TODO: handle reading and posting referral codes

  return (
    <HomeView
      isFullScreen={isFullScreen}
      notMobile={notMobile}
      showWhatsNew={showWhatsNew}
      onCloseWhatsNew={handleCloseWhatsNew}
    />
  );
}

HomeContainer.propTypes = {
  isFullScreen: propTypes.bool,
  notMobile: propTypes.bool,
};

HomeContainer.defaultProps = {
  isFullScreen: false,
  notMobile: false,
};

export default HomeContainer;
