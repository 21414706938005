import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    textAlign: 'left',
    minWidth: 275,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export default useStyles;
