import { makeStyles } from '@mui/styles';

import {
  blueGrey,
  lightGreen,
  deepOrange,
} from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    color: theme.palette.getContrastText(lightGreen[500]),
    backgroundColor: lightGreen[500],
    '&:hover': {
      backgroundColor: lightGreen[700],
    },
  },
  warningButton: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    '&:hover': {
      backgroundColor: deepOrange[700],
    },
  },
  logo: {
    maxWidth: 140,
    justifyContent: 'center',
    flexGrow: 1,
  },
  cancelButton: {
    color: theme.palette.getContrastText(blueGrey[500]),
    // backgroundColor: blueGrey[500],
    // '&:hover': {
    //   backgroundColor: blueGrey[700],
    // },
  },
}));

export default useStyles;
