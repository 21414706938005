import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';

import EmailVerificationView from './EmailVerificationView';

import { useAccountStatus, useRefreshAccount } from '../Account';

function EmailVerificationContainer() {
  const [isEmailUpdated, setIsEmailUpdated] = useState(false);
  const [isEmailVerificationSent, setIsEmailVerificationSent] = useState(false);
  const [shouldUpdateEmail, setShouldUpdateEmail] = useState(false);
  const [shouldSendEmailVerification, setShouldSendEmailVerification] = useState(false);
  const [email, setEmail] = useState('');
  const [emailUpdateError, setEmailUpdateError] = useState('');
  const [emailVerificationError, setEmailVerificationError] = useState('');
  const [timer, setTimer] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const firebase = useFirebase();

  const {
    isLoading,
    isLoggedIn,
    isEmailProvided,
    isEmailVerified,
  } = useAccountStatus();

  const refreshAccount = useRefreshAccount();

  const componentStatus = useRef(true);

  const { from } = location.state || { from: { pathname: '/home' } };

  useEffect(() => {
    setTimer(setInterval(() => refreshAccount(), 2000));

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => () => {
    componentStatus.current = false;
  }, []);

  useEffect(() => {
    if (!isLoading && isLoggedIn && isEmailProvided && isEmailVerified) {
      history.replace(from);
    }
  }, [
    isLoading,
    isLoggedIn,
    isEmailProvided,
    isEmailVerified,
  ]);

  useEffect(() => {
    if (shouldUpdateEmail === true) {
      setEmailUpdateError('');

      let errorMessage = '';

      (async () => {
        try {
          await firebase.auth()
            .currentUser
            .updateEmail(email);
        } catch (error) {
          errorMessage = error.message;
        }
      })();

      if (componentStatus.current === true) {
        setIsEmailUpdated(!errorMessage);
        setShouldUpdateEmail(false);
        setEmail('');
        setEmailUpdateError(errorMessage);
      }
    }
  }, [
    shouldUpdateEmail,
    firebase,
    email,
  ]);

  useEffect(() => {
    if (shouldSendEmailVerification === true) {
      setEmailVerificationError('');

      let errorMessage;

      (async () => {
        try {
          await firebase.auth()
            .currentUser
            .sendEmailVerification();
        } catch (error) {
          errorMessage = error.message;
        }
      })();

      if (componentStatus.current === true) {
        setIsEmailVerificationSent(!errorMessage);
        setEmailVerificationError(errorMessage);
        setShouldSendEmailVerification(false);
      }
    }
  }, [
    shouldSendEmailVerification,
    firebase,
  ]);

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleClickEmailUpdate = () => setShouldUpdateEmail(true);

  const handleClickEmailVerify = () => setShouldSendEmailVerification(true);

  return (
    <EmailVerificationView
      isLoading={isLoading}
      isEmailProvided={isEmailProvided}
      isEmailUpdated={isEmailUpdated}
      isEmailVerified={isEmailVerified}
      isEmailVerificationSent={isEmailVerificationSent}
      emailUpdateError={emailUpdateError}
      emailVerificationError={emailVerificationError}
      onEmailChange={handleEmailChange}
      onClickEmailUpdate={handleClickEmailUpdate}
      onClickEmailVerify={handleClickEmailVerify}
    />
  );
}

export default EmailVerificationContainer;
