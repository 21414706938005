import React, { useCallback, useContext } from 'react';
import propTypes from 'prop-types';

import PageItemView from './PageItemView';

import { dialogPageItemEditOpen } from '../../actions';
import PageListContext from '../../context';
import { useEventEmitter, SEVERITY_ERROR } from '../../../EventListener';
import { usePageItemNextState } from '../../hooks';

function PageItemContainer(props) {
  const {
    pageId,
    id,
    state,
    content,
  } = props;

  const dispatch = useContext(PageListContext);
  const emitEvent = useEventEmitter();

  const pageItemNextState = usePageItemNextState(
    (error) => emitEvent(
      SEVERITY_ERROR,
      error.message,
    ),
  );

  const handleClickPageItem = (e) => {
    e.preventDefault();

    pageItemNextState(pageId, id, e);
  };

  const handleClickPageItemEdit = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(dialogPageItemEditOpen(pageId, id));
    },
    [dispatch, pageId, id],
  );

  return (
    <PageItemView
      id={id}
      state={state}
      content={content}
      onClickPageItem={handleClickPageItem}
      onClickPageItemEdit={handleClickPageItemEdit}
    />
  );
}

PageItemContainer.propTypes = {
  pageId: propTypes.string.isRequired,
  id: propTypes.number.isRequired,
  state: propTypes.number,
  content: propTypes.string,
};

PageItemContainer.defaultProps = {
  state: 0,
  content: '',
};

export default PageItemContainer;
