import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import useStyles from '../styles';

const RouteDelayView = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={64} />
    </div>
  );
};

export default RouteDelayView;
