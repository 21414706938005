import React from 'react';
import propTypes from 'prop-types';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import { DialogTitle } from '@mui/material';

import useStyles from './styles';

function PageItemEditDialogView(props) {
  const {
    isFullScreen,
    isPageItemEditOpen,
    onChangePageItemContent,
    onClickPageItemUpdate,
    onClickPageItemDelete,
    onClosePageItemEdit,
    activePageItemContent,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={isPageItemEditOpen}
      onClose={onClosePageItemEdit}
      aria-labelledby="form-dialog-title"
      fullScreen={isFullScreen}
    >
      <form onSubmit={onClickPageItemUpdate}>
        <DialogTitle>
          Edit Item
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can change your item to whatever you like, or delete it.
            All changes and deletions are permanent, and cannot be
            recovered.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="itemContents"
            label="Item Contents"
            fullWidth
            value={activePageItemContent}
            onChange={onChangePageItemContent}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.redButton}
            onClick={onClickPageItemDelete}
            size="small"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
          <Button
            className={classes.greenButton}
            type="submit"
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

PageItemEditDialogView.propTypes = {
  isFullScreen: propTypes.bool,
  isPageItemEditOpen: propTypes.bool,
  onChangePageItemContent: propTypes.func.isRequired,
  onClickPageItemUpdate: propTypes.func.isRequired,
  onClickPageItemDelete: propTypes.func.isRequired,
  onClosePageItemEdit: propTypes.func.isRequired,
  activePageItemContent: propTypes.string,
};

PageItemEditDialogView.defaultProps = {
  isFullScreen: false,
  isPageItemEditOpen: false,
  activePageItemContent: '',
};

export default PageItemEditDialogView;
