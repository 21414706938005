const timeout = async (ms, onCancel) => {
  let tout;

  try {
    await new Promise((resolve, reject) => {
      if (onCancel) {
        onCancel(() => reject());
      }

      tout = setTimeout(resolve, ms);
    });
  } catch (_) {
    clearTimeout(tout);
  }
};

export default timeout;
