import React, { useContext, useState, useEffect } from 'react';
import propTypes from 'prop-types';

import SettingsDialogView from './SettingsDialogView';

import MenuContext from '../../context';

import {
  dialogSettingsClose,
} from '../../actions';

function SettingsDialogContainer(props) {
  const {
    isFullScreen,
    isSettingsDialogOpen,
    isSettingsDialogLoading,
  } = props;

  const dispatch = useContext(MenuContext);
  const [darkMode, setDarkMode] = useState('automatic');
  const [completionMode, setCompletionMode] = useState('leftClick');

  useEffect(() => {
    const storedOverride = localStorage.getItem('overridePallete');
    if (storedOverride) {
      setDarkMode(storedOverride);
    }
    const storedCompletionMode = localStorage.getItem('completionMode');
    if (storedCompletionMode) {
      setCompletionMode(storedCompletionMode);
    }
  }, []);

  const handleCloseAccountDialog = () => dispatch(dialogSettingsClose());

  const handleChangeDarkMode = (e) => {
    localStorage.setItem('overridePallete', e.target.value);
    setDarkMode(e.target.value);
  };

  const handleChangeCompletionMode = (e) => {
    localStorage.setItem('completionMode', e.target.value);
    setCompletionMode(e.target.value);
  };

  return (
    <SettingsDialogView
      isFullScreen={isFullScreen}
      isSettingsDialogOpen={isSettingsDialogOpen}
      isSettingsDialogLoading={isSettingsDialogLoading}
      onCloseAccountDialog={handleCloseAccountDialog}
      darkMode={darkMode}
      onChangeDarkMode={handleChangeDarkMode}
      completionMode={completionMode}
      onChangeCompletionMode={handleChangeCompletionMode}
    />
  );
}

SettingsDialogContainer.propTypes = {
  isFullScreen: propTypes.bool,
  isSettingsDialogOpen: propTypes.bool,
  isSettingsDialogLoading: propTypes.bool,
};

SettingsDialogContainer.defaultProps = {
  isFullScreen: false,
  isSettingsDialogOpen: false,
  isSettingsDialogLoading: false,
};

export default SettingsDialogContainer;
