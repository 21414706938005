export const DIALOG_SIGN_IN_OPEN = 'DIALOG_SIGN_IN_OPEN';
export const DIALOG_SIGN_IN_CLOSE = 'DIALOG_SIGN_IN_CLOSE';

export const dialogSignInOpen = () => ({ type: DIALOG_SIGN_IN_OPEN });
export const dialogSignInClose = () => ({ type: DIALOG_SIGN_IN_CLOSE });

export const DIALOG_SIGN_UP_OPEN = 'DIALOG_SIGN_UP_OPEN';
export const DIALOG_SIGN_UP_CLOSE = 'DIALOG_SIGN_UP_CLOSE';

export const dialogSignUpOpen = () => ({ type: DIALOG_SIGN_UP_OPEN });
export const dialogSignUpClose = () => ({ type: DIALOG_SIGN_UP_CLOSE });

export const DIALOG_PASSWORD_RESET_OPEN = 'DIALOG_PASSWORD_RESET_OPEN';
export const DIALOG_PASSWORD_RESET_CLOSE = 'DIALOG_PASSWORD_RESET_CLOSE';

export const dialogPasswordResetOpen = () => ({ type: DIALOG_PASSWORD_RESET_OPEN });
export const dialogPasswordResetClose = () => ({ type: DIALOG_PASSWORD_RESET_CLOSE });

export const SIGN_IN_INTERNAL_START = 'SIGN_IN_INTERNAL_START';
export const SIGN_IN_INTERNAL_FINISH = 'SIGN_IN_INTERNAL_FINISH';

export const signInInternalStart = () => ({ type: SIGN_IN_INTERNAL_START });
export const signInInternalFinish = () => ({ type: SIGN_IN_INTERNAL_FINISH });

export const SIGN_IN_EXTERNAL_START = 'SIGN_IN_EXTERNAL_START';
export const SIGN_IN_EXTERNAL_FINISH = 'SIGN_IN_EXTERNAL_FINISH';

export const signInExternalStart = () => ({ type: SIGN_IN_EXTERNAL_START });
export const signInExternalFinish = () => ({ type: SIGN_IN_EXTERNAL_FINISH });

export const PASSWORD_RESET_START = 'PASSWORD_RESET_START';
export const PASSWORD_RESET_FINISH = 'PASSWORD_RESET_FINISH';

export const passwordResetStart = () => ({ type: PASSWORD_RESET_START });
export const passwordResetFinish = () => ({ type: PASSWORD_RESET_FINISH });

export const SIGN_UP_START = 'SIGN_UP_START';
export const SIGN_UP_FINISH = 'SIGN_UP_FINISH';

export const signUpStart = () => ({ type: SIGN_UP_START });
export const signUpFinish = () => ({ type: SIGN_UP_FINISH });
