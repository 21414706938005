import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import useStyles from './styles';

export function PricingView() {
  const classes = useStyles();

  return (
    <Card
      className={classes.card}
      variant="outlined"
    >
      <CardHeader title="Pricing" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Screw Notes is free to start using straight away. Get a Pro
              Subscription to unlock the full app!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="pricing table">
                <TableHead>
                  <TableRow>
                    <TableCell>Feature</TableCell>
                    <TableCell align="center">Free</TableCell>
                    <TableCell align="center">Pro (AUD$12/Year)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Pages
                    </TableCell>
                    <TableCell align="center">3</TableCell>
                    <TableCell align="center">Unlimited</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Items
                    </TableCell>
                    <TableCell align="center">20 Per Page</TableCell>
                    <TableCell align="center">Unlimited</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default PricingView;
