/* eslint-disable react/prop-types */
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import qs from "query-string";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CookieConsent from "react-cookie-consent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { red, blueGrey, lightGreen } from "@mui/material/colors";
import "typeface-roboto";

import EmailVerification from "./EmailVerification";
import Home from "./Home";
import Header from "./Header";
import Welcome from "./Welcome";
import { addEventListener } from "./EventListener";
import ProtectedRoute from "./ProtectedRoute";
import { useDisplayStatus } from "../utils";

const theme = (paletteType) =>
  createTheme({
    palette: {
      mode: paletteType === "light" ? "light" : "dark",
      primary: {
        main: blueGrey[500],
      },
      secondary: {
        main: lightGreen[500],
      },
      error: {
        main: red[500],
      },
    },
  });

function App(props) {
  const { location } = props;

  const displayStatus = useDisplayStatus();

  let paletteType = "light";
  // set the palette first by media query
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const notMobile = useMediaQuery("(min-width:600px)");
  if (prefersDarkMode) {
    paletteType = "dark";
  }

  // then set it by override, if it has been defined
  const overridePallete = localStorage.getItem("overridePallete");
  if (overridePallete === "dark") {
    paletteType = "dark";
  } else if (overridePallete === "light") {
    paletteType = "light";
  }

  // apply the palette to the theme
  const modifiedTheme = theme(paletteType);

  // check for a referral code in the query string
  const params = qs.parse(location.search);
  if (params && params.referralCode) {
    const postedReferralCodes = localStorage.getItem("postedReferralCodes");
    let alreadyPosted = false;
    if (postedReferralCodes) {
      const parsedPostedReferralCodes = JSON.parse(postedReferralCodes);
      parsedPostedReferralCodes.forEach((postedCode) => {
        if (postedCode === params.referralCode) {
          alreadyPosted = true;
        }
      });
    }

    if (!alreadyPosted) {
      const currentReferralCode = localStorage.getItem("referralCode");
      if (currentReferralCode && currentReferralCode !== params.referralCode) {
        localStorage.setItem("referralCode", params.referralCode);
      }
    }
  }

  return (
    <ThemeProvider theme={modifiedTheme}>
      <CssBaseline />
      <Paper square>
        <Header isFullScreen={displayStatus.isFullScreen} paletteType={paletteType} />
        <main className="content-inside">
          <BrowserRouter>
            <Switch>
              <ProtectedRoute exact path="/">
                <Home
                  isFullScreen={displayStatus.isFullScreen}
                  notMobile={notMobile}
                />
              </ProtectedRoute>
              <Route exact path="/login">
                <Welcome
                  isFullScreen={displayStatus.isFullScreen}
                  notMobile={notMobile}
                />
              </Route>
              <ProtectedRoute exact path="/verify">
                <EmailVerification />
              </ProtectedRoute>
              <ProtectedRoute path="/:message">
                <Home
                  isFullScreen={displayStatus.isFullScreen}
                  notMobile={notMobile}
                />
              </ProtectedRoute>
            </Switch>
          </BrowserRouter>
        </main>
      </Paper>
      <Paper className="footer">
        <Typography variant="overline" display="block" gutterBottom>
          Screw Notes &copy; {new Date().getFullYear()} Congruent Labs Pty Ltd
        </Typography>
      </Paper>
      <CookieConsent>
        This website uses cookies to provide authentication services, and to
        assist in fraud detection for payments. More information can be found in
        the
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://firebasestorage.googleapis.com/v0/b/screwnotes.appspot.com/o/Privacy%20Policy.pdf?alt=media&token=9452d4c4-0e32-42aa-a38c-4946430655ff"
        >
          {" "}
          Privacy Policy
        </a>
        .
      </CookieConsent>
    </ThemeProvider>
  );
}

export default addEventListener(App, 6000);
