import React from 'react';
import propTypes from 'prop-types';

import clsx from 'clsx';

import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

import Menu from './Menu';

import useStyles from './styles';

function HeaderView(props) {
  const {
    isFullScreen,
    isLoggedIn,
    isEmailVerified,
    isMenuOpen,
    anchorEl,
    onMenuClose,
    onClickMenu,
    onClickSubscription,
    onClickAccount,
    onClickSettings,
    onClickLogout,
    isSubscriptionDialogOpen,
    isSubscriptionDialogLoading,
    isAccountDialogOpen,
    isAccountDialogLoading,
    isSettingsDialogOpen,
    isSettingsDialogLoading,
    isDeleteAccountDialogOpen,
    isDeleteAccountDialogLoading,
    paletteType,
  } = props;

  const classes = useStyles();

  return (
    <AppBar position="static">
      <Toolbar>
        <Container maxWidth="sm">
          <IconButton
            edge="start"
            className={
              clsx(
                classes.menuButton,
                (!isLoggedIn && !isEmailVerified) && classes.buttonDisabled,
              )
            }
            color="inherit"
            aria-label="application menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            disableRipple
            onClick={onClickMenu}
          >
            <MenuIcon className={
              (isLoggedIn && isEmailVerified)
                ? classes.visible
                : classes.hidden
            }
            />
          </IconButton>
          <Button disabled>
            <img src={paletteType === 'light' ? "/logo-big.png" : "/logo-big-dark.png" } alt="logo" className={classes.logo} />
          </Button>

          {isLoggedIn && isEmailVerified && (
            <Menu
              isFullScreen={isFullScreen}
              isMenuOpen={isMenuOpen}
              anchorEl={anchorEl}
              onMenuClose={onMenuClose}
              onClickSubscription={onClickSubscription}
              onClickAccount={onClickAccount}
              onClickSettings={onClickSettings}
              onClickLogout={onClickLogout}
              isSubscriptionDialogOpen={isSubscriptionDialogOpen}
              isSubscriptionDialogLoading={isSubscriptionDialogLoading}
              isAccountDialogOpen={isAccountDialogOpen}
              isAccountDialogLoading={isAccountDialogLoading}
              isSettingsDialogOpen={isSettingsDialogOpen}
              isSettingsDialogLoading={isSettingsDialogLoading}
              isDeleteAccountDialogOpen={isDeleteAccountDialogOpen}
              isDeleteAccountDialogLoading={isDeleteAccountDialogLoading}
            />
          )}
        </Container>
      </Toolbar>
    </AppBar>
  );
}

HeaderView.propTypes = {
  isFullScreen: propTypes.bool,
  isLoggedIn: propTypes.bool,
  isEmailVerified: propTypes.bool,
  isMenuOpen: propTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: propTypes.object,
  onMenuClose: propTypes.func.isRequired,
  onClickMenu: propTypes.func.isRequired,
  onClickSubscription: propTypes.func.isRequired,
  onClickAccount: propTypes.func.isRequired,
  onClickSettings: propTypes.func.isRequired,
  onClickLogout: propTypes.func.isRequired,
  isSubscriptionDialogOpen: propTypes.bool,
  isSubscriptionDialogLoading: propTypes.bool,
  isAccountDialogOpen: propTypes.bool,
  isAccountDialogLoading: propTypes.bool,
  isSettingsDialogOpen: propTypes.bool,
  isSettingsDialogLoading: propTypes.bool,
  isDeleteAccountDialogOpen: propTypes.bool,
  isDeleteAccountDialogLoading: propTypes.bool,
};

HeaderView.defaultProps = {
  isFullScreen: false,
  isLoggedIn: false,
  isEmailVerified: false,
  isMenuOpen: false,
  anchorEl: null,
  isSubscriptionDialogOpen: false,
  isSubscriptionDialogLoading: false,
  isAccountDialogOpen: false,
  isAccountDialogLoading: false,
  isSettingsDialogOpen: false,
  isSettingsDialogLoading: false,
  isDeleteAccountDialogOpen: false,
  isDeleteAccountDialogLoading: false,
};

export default HeaderView;
