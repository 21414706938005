import React, { useReducer, useState } from "react";

import propTypes from "prop-types";

import HeaderView from "./HeaderView";

import HeaderContext from "./context";
import headerReducer, { initialState } from "./reducer";
import { useEventEmitter, SEVERITY_ERROR } from "../EventListener";

import { useAccountStatus, useLogout } from "../Account";

import {
  dialogAccountOpen,
  dialogSubscriptionOpen,
  dialogSettingsOpen,
  logOutStart,
} from "./actions";

import { useSafeDispatch } from "../../utils/hooks";

function HeaderContainer(props) {
  const { isFullScreen, paletteType } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const [state, dispatch] = useReducer(headerReducer, initialState);

  const emitEvent = useEventEmitter();
  const safeDispatch = useSafeDispatch(dispatch);

  const logout = useLogout(
    (error) => {
      emitEvent(SEVERITY_ERROR, error.message);
    },
    () => window.scroll(0, 0)
  );

  const accountStatus = useAccountStatus();

  const { isLoggedIn, isEmailVerified } = accountStatus;

  const handleClickMenu = (e) => setAnchorEl(e.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handleClickAccount = () => {
    handleMenuClose();

    dispatch(dialogAccountOpen());
  };

  const handleClickSubscription = () => {
    handleMenuClose();

    dispatch(dialogSubscriptionOpen());
  };

  const handleClickLogout = () => {
    safeDispatch(logOutStart());

    logout();

    handleMenuClose();
  };

  const handleClickSettings = () => {
    handleMenuClose();

    dispatch(dialogSettingsOpen());
  };

  return (
    <HeaderContext.Provider value={safeDispatch}>
      <HeaderView
        isFullScreen={isFullScreen}
        isLoggedIn={isLoggedIn}
        isEmailVerified={isEmailVerified}
        isMenuOpen={!!anchorEl}
        anchorEl={anchorEl}
        onMenuClose={handleMenuClose}
        onClickMenu={handleClickMenu}
        onClickSubscription={handleClickSubscription}
        onClickAccount={handleClickAccount}
        onClickLogout={handleClickLogout}
        onClickSettings={handleClickSettings}
        isSubscriptionDialogOpen={state.isSubscriptionDialogOpen}
        isSubscriptionDialogLoading={state.isSubscriptionDialogLoading}
        isAccountDialogOpen={state.isAccountDialogOpen}
        isAccountDialogLoading={state.isAccountDialogLoading}
        isSettingsDialogOpen={state.isSettingsDialogOpen}
        isSettingsDialogLoading={state.isSettingsDialogLoading}
        isDeleteAccountDialogOpen={state.isDeleteAccountDialogOpen}
        isDeleteAccountDialogLoading={state.isDeleteAccountDialogLoading}
        paletteType={paletteType}
      />
    </HeaderContext.Provider>
  );
}

HeaderContainer.propTypes = {
  isFullScreen: propTypes.bool,
  paletteType: propTypes.string,
};

HeaderContainer.defaultProps = {
  isFullScreen: false,
  paletteType: "light",
};

export default HeaderContainer;
