/* eslint-disable react/jsx-props-no-spreading */
import React, { useReducer } from 'react';
import propTypes from 'prop-types';

import EventToastContainer from './EventToast';
import reducer, { initialState } from './reducer';
import EventListenerContext from './context';

const addEventListener = (Component, autoHideDuration) => {
  function EventListenerContainer({ forwardedRef, ...rest }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { isOpen } = state;

    let severity = null;
    let message = null;

    if (state.loadedEvent) {
      severity = state.loadedEvent.severity;
      message = state.loadedEvent.message;
    }

    return (
      <EventListenerContext.Provider value={dispatch}>
        <Component ref={forwardedRef} {...rest} />
        <EventToastContainer
          isOpen={isOpen}
          severity={severity}
          message={message}
          autoHideDuration={autoHideDuration}
        />
      </EventListenerContext.Provider>
    );
  }

  EventListenerContainer.propTypes = {
    autoHideDuration: propTypes.number,
    forwardedRef: propTypes.instanceOf(React.Ref),
  };

  EventListenerContainer.defaultProps = {
    autoHideDuration: null,
    forwardedRef: null,
  };

  return React.forwardRef((props, ref) => (
    <EventListenerContainer {...props} forwardedRef={ref} />
  ));
};

export default addEventListener;
