import React from 'react';
import propTypes from 'prop-types';

import Container from '@mui/material/Container';

import Login from '../Login';
import About from '../About';
import Pricing from '../Pricing';

function WelcomeView(props) {
  const { isFullScreen } = props;

  return (
    <Container maxWidth="sm">
      <Login
        fullScreen={isFullScreen}
      />
      <About />
      <Pricing />
    </Container>
  );
}

WelcomeView.propTypes = {
  isFullScreen: propTypes.bool,
};

WelcomeView.defaultProps = {
  isFullScreen: false,
};

export default WelcomeView;
