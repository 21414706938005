import { useEffect, useState, useCallback } from 'react';

import { useSelector } from 'react-redux';

import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestoreConnect,
} from 'react-redux-firebase';

import { isDev } from '../../config';
import { timeout, useExecutor } from '../../utils';

export const AUTH_GOOGLE = 'AUTH_GOOGLE';
export const AUTH_FACEBOOK = 'AUTH_FACEBOOK';
export const AUTH_GITHUB = 'AUTH_GITHUB';
export const AUTH_TWITTER = 'AUTH_TWITTER';

const PROV_GOOGLE = {
  provider: 'google',
  type: 'popup',
};

const PROV_FACEBOOK = {
  provider: 'facebook',
  type: 'popup',
};

const PROV_GITHUB = {
  provider: 'github',
  type: 'popup',
};

const PROV_TWITTER = {
  provider: 'twitter',
  type: 'popup',
};

const getProvider = (provider) => {
  switch (provider) {
    case AUTH_GOOGLE:
      return ({
        ...PROV_GOOGLE,
      });

    case AUTH_FACEBOOK:
      return ({
        ...PROV_FACEBOOK,
      });

    case AUTH_GITHUB:
      return ({
        ...PROV_GITHUB,
      });

    case AUTH_TWITTER:
      return ({
        ...PROV_TWITTER,
      });

    default:
      throw Error(`Unrecognised provider ${provider} provided.`);
  }
};

export const useRefreshAccount = (onError, onSuccess) => {
  const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);

  return async () => {
    try {
      await firebase.reloadAuth();

      await timeout(6000, async (cancel) => {
        while (!isLoaded(auth)) {
        // eslint-disable-next-line no-await-in-loop
          await timeout(1000);
        }
        cancel();
      });

      if (onSuccess) {
        await onSuccess();
      }
    } catch (error) {
      if (onError) {
        await onError(error);
      }
    }
  };
};

export const useAccountStatus = () => {
  const auth = useSelector((state) => state.firebase.auth);

  const getAccountStatus = useCallback(() => {
    let userId = '';
    let isLoggedIn = false;
    let isLoading = false;
    let isEmailProvided = false;
    let isEmailVerified = false;
    let customerEmail = '';

    isLoading = !isLoaded(auth);

    if (isLoaded(auth) && !isEmpty(auth)) {
      userId = auth.uid;
      isLoggedIn = true;
      isEmailProvided = !!auth.email;
      isEmailVerified = !!auth.emailVerified;

      if (isEmailProvided) {
        customerEmail = auth.email;
      }
    }

    return ({
      userId,
      isLoggedIn,
      isLoading,
      isEmailProvided,
      isEmailVerified,
      customerEmail,
    });
  }, [auth]);

  const [accountStatus, setAccountStatus] = useState(getAccountStatus());

  useEffect(() => {
    setAccountStatus(getAccountStatus());
  }, [getAccountStatus]);

  return accountStatus;
};

export const useSubscriptionStatus = () => {
  const auth = useSelector((state) => state.firebase.auth);

  useFirestoreConnect([{
    collection: 'subscriptions',
    where: ['creatorId', '==', auth.uid],
    storeAs: 'mySubscriptions',
  }]);

  const subscriptions = useSelector((state) => state.firestore.ordered.mySubscriptions);

  const getSubscriptionStatus = useCallback(() => {
    let hasPro = false;

    if (isLoaded(subscriptions)) {
      subscriptions.forEach((sub) => {
        if (sub.status
          && (sub.status === 'active' || sub.status === 'trialing')) {
          hasPro = true;
        }
      });
    }

    return hasPro;
  }, [subscriptions]);

  const [hasSubscription, setHasSubscription] = useState(getSubscriptionStatus);

  useEffect(() => {
    setHasSubscription(getSubscriptionStatus);
  }, [getSubscriptionStatus]);

  return hasSubscription;
};

export const useExternalLogin = (onError, onSuccess) => {
  const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);
  const refreshAccount = useRefreshAccount();

  const [provider, setProvider] = useState('');

  const memoisedFunc = useCallback(async () => {
    let loginResult;

    try {
      loginResult = await firebase.login(getProvider(provider));
    } catch (error) {
      if (onError) {
        await onError(error);
      }

      return;
    }

    if (!!loginResult
      && !!loginResult.additionalUserInfo
      && !!firebase.auth().currentUser
      && !!loginResult.additionalUserInfo.profile
      && !!loginResult.additionalUserInfo.profile.email
    ) {
      await timeout(6000, async (cancel) => {
        while (!isLoaded(auth)) {
          // eslint-disable-next-line no-await-in-loop
          await timeout(1000);
        }
        cancel();
      });

      if (!!auth && !auth.email) {
        try {
          await firebase.auth()
            .currentUser
            .updateEmail(loginResult.additionalUserInfo.profile.email);

          await refreshAccount();
        } catch (error) {
          if (onError) {
            await onError(error);
          }
        }
      }
    }

    if (onSuccess) {
      await onSuccess();
    }
  }, [
    firebase,
    auth,
    refreshAccount,
    provider,
    onSuccess,
    onError,
  ]);

  const execute = useExecutor(memoisedFunc);

  return (idProvider) => () => {
    setProvider(idProvider);

    execute();
  };
};

export const useInternalLogin = (onError, onSuccess) => {
  const firebase = useFirebase();

  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const memoisedFunc = useCallback(async () => {
    try {
      await firebase.auth()
        .signInWithEmailAndPassword(
          userEmail,
          userPassword,
        );

      if (onSuccess) {
        await onSuccess();
      }
    } catch (error) {
      if (onError) {
        await onError(error);
      }
    }
  }, [
    onError,
    onSuccess,
    firebase,
    userEmail,
    userPassword,
  ]);

  const execute = useExecutor(memoisedFunc);

  return (email, password) => {
    setUserEmail(email);
    setUserPassword(password);

    execute();
  };
};

export const useSignUp = (onError, onSuccess) => {
  const firebase = useFirebase();

  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const memoisedFunc = useCallback(async () => {
    try {
      await firebase.auth()
        .createUserWithEmailAndPassword(
          userEmail,
          userPassword,
        );

      if (onSuccess) {
        await onSuccess();
      }
    } catch (error) {
      if (onError) {
        await onError(error);
      }
    }
  }, [
    onError,
    onSuccess,
    firebase,
    userEmail,
    userPassword,
  ]);

  const execute = useExecutor(memoisedFunc);

  return async (email, password) => {
    setUserEmail(email);
    setUserPassword(password);

    execute();
  };
};

export const usePasswordReset = (onError, onSuccess) => {
  const firebase = useFirebase();

  const [userEmail, setUserEmail] = useState('');

  const memoisedFunc = useCallback(async () => {
    try {
      await firebase.resetPassword(userEmail);

      if (onSuccess) {
        await onSuccess();
      }
    } catch (error) {
      if (error.code !== 'auth/user-not-found') {
        if (onError) {
          await onError(error);
        }
      }
    }
  }, [
    onError,
    onSuccess,
    firebase,
    userEmail,
  ]);

  const execute = useExecutor(memoisedFunc);

  return (email) => {
    setUserEmail(email);

    execute();
  };
};

export const useLogout = (onError, onSuccess) => {
  const firebase = useFirebase();

  const memoisedFunc = useCallback(async () => {
    try {
      await firebase.logout();

      if (onSuccess) {
        await onSuccess();
      }
    } catch (error) {
      if (onError) {
        await onError(error);
      }
    }
  }, [
    onError,
    onSuccess,
    firebase,
  ]);

  const execute = useExecutor(memoisedFunc);

  return () => execute();
};

export const usePurchaseSubscription = (onError, onSuccess) => {
  const stripe = isDev()
    ? window.Stripe('pk_test_Uek7uVHTlSM6LTlyVpfvomnh00vhHM3CJB')
    : window.Stripe('pk_live_em1nRpJgSZUPrLzCAgJ8LBmw00gxYmsEkK');

  const [email, setEmail] = useState('');

  const memoisedFunc = useCallback(async () => {
    const result = await stripe.redirectToCheckout({
      items: [{
        plan: isDev()
          ? 'plan_GgKmTzHiAgDnDo'
          : 'plan_Gfxn98iIC4Hwap',
        quantity: 1,
      }],
      successUrl: isDev()
        ? 'http://localhost:3000/PaymentSuccess?session_id={CHECKOUT_SESSION_ID}'
        : 'https://screwnotes.com/PaymentSuccess?session_id={CHECKOUT_SESSION_ID}',
      cancelUrl: isDev()
        ? 'http://localhost:3000/PaymentFailed'
        : 'https://screwnotes.com/PaymentFailed',
      customerEmail: email,
    });

    if (result.error) {
      if (onError) {
        await onError(Error(result.error));
      }
    } else if (onSuccess) {
      await onSuccess();
    }
  }, [
    onError,
    onSuccess,
    stripe,
    email,
  ]);

  const execute = useExecutor(memoisedFunc);

  return (customerEmail) => {
    setEmail(customerEmail);

    execute();
  };
};

export const useCancelSubscription = (onError, onSuccess) => {
  const firebase = useFirebase();
  const refreshAccount = useRefreshAccount();

  const memoisedFunc = useCallback(async () => {
    try {
      const result = await firebase.functions()
        .httpsCallable('cancelSubscription')();

      if (!result.subscriptionCancelled) {
        if (onError) {
          await onError(Error('Subscription cancellation failed'));
        }
      }

      await refreshAccount();

      if (onSuccess) {
        await onSuccess();
      }
    } catch (error) {
      if (onError) {
        await onError(
          Error('Subscription cancellation failed due to error.'
          + `Received message '${error}'`),
        );
      }
    }
  }, [
    onError,
    onSuccess,
    firebase,
    refreshAccount,
  ]);

  const execute = useExecutor(memoisedFunc);

  return () => execute();
};

export const useChangeEmail = (onError, onSuccess) => {
  const firebase = useFirebase();
  const refreshAccount = useRefreshAccount();

  const [userEmail, setUserEmail] = useState('');

  const memoisedFunc = useCallback(async () => {
    try {
      await firebase.auth()
        .currentUser
        .updateEmail(userEmail);

      await refreshAccount();

      if (onSuccess) {
        await onSuccess();
      }
    } catch (error) {
      if (onError) {
        await onError(error);
      }
    }
  }, [
    onError,
    onSuccess,
    firebase,
    refreshAccount,
  ]);

  const execute = useExecutor(memoisedFunc);

  return (email) => {
    setUserEmail(email);

    execute();
  };
};

export const useDeleteAccount = (onError, onSuccess) => {
  const firebase = useFirebase();
  const refreshAccount = useRefreshAccount();

  const memoisedFunc = useCallback(async () => {
    try {
      await firebase.functions()
        .httpsCallable('deleteUser')({});

      await refreshAccount();

      if (onSuccess) {
        await onSuccess();
      }
    } catch (error) {
      if (onError) {
        await onError(error);
      }
    }
  }, [
    onError,
    onSuccess,
    firebase,
    refreshAccount,
  ]);

  const execute = useExecutor(memoisedFunc);

  return () => execute();
};
