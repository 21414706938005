import { makeStyles } from '@mui/styles';
import { blue } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  sectionMiddle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  blueButton: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
}));

export default useStyles;
