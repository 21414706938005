import {
  DIALOG_SIGN_IN_OPEN,
  DIALOG_SIGN_IN_CLOSE,
  DIALOG_SIGN_UP_OPEN,
  DIALOG_SIGN_UP_CLOSE,
  DIALOG_PASSWORD_RESET_OPEN,
  DIALOG_PASSWORD_RESET_CLOSE,
  SIGN_IN_INTERNAL_START,
  SIGN_IN_INTERNAL_FINISH,
  SIGN_IN_EXTERNAL_START,
  SIGN_IN_EXTERNAL_FINISH,
  PASSWORD_RESET_START,
  PASSWORD_RESET_FINISH,
  SIGN_UP_START,
  SIGN_UP_FINISH,
} from './actions';

export const initialState = {
  isSignInOpen: false,
  isSignInLoading: false,
  isSignUpOpen: false,
  isSignUpLoading: false,
  isPasswordResetOpen: false,
  isPasswordResetLoading: false,
};

const loginReducer = (state, action) => {
  switch (action.type) {
    case DIALOG_SIGN_IN_OPEN:
      return {
        ...initialState,
        isSignInOpen: true,
      };

    case DIALOG_SIGN_IN_CLOSE:
      return {
        ...initialState,
        isSignInOpen: false,
      };

    case DIALOG_SIGN_UP_OPEN:
      return {
        ...initialState,
        isSignUpOpen: true,
      };

    case DIALOG_SIGN_UP_CLOSE:
      return {
        ...initialState,
        isSignUpOpen: false,
      };

    case DIALOG_PASSWORD_RESET_OPEN:
      return {
        ...initialState,
        isPasswordResetOpen: true,
      };

    case DIALOG_PASSWORD_RESET_CLOSE:
      return {
        ...initialState,
        isPasswordResetOpen: false,
      };

    case SIGN_IN_INTERNAL_START:
      return {
        ...state,
        isSignInLoading: true,
      };

    case SIGN_IN_INTERNAL_FINISH:
      return {
        ...state,
        isSignInLoading: false,
      };

    case SIGN_IN_EXTERNAL_START:
      return {
        ...state,
        isSignInLoading: (state.isSignInOpen)
          ? true
          : state.isSignInLoading,
        isSignUpLoading: (state.isSignUpOpen)
          ? true
          : state.isSignUpLoading,
      };

    case SIGN_IN_EXTERNAL_FINISH:
      return {
        ...state,
        isSignInLoading: (state.isSignInOpen)
          ? false
          : state.isSignInLoading,
        isSignUpLoading: (state.isSignUpOpen)
          ? false
          : state.isSignUpLoading,
      };

    case SIGN_UP_START:
      return {
        ...state,
        isSignUpLoading: true,
      };

    case SIGN_UP_FINISH:
      return {
        ...state,
        isSignUpLoading: false,
      };

    case PASSWORD_RESET_START:
      return {
        ...state,
        isPasswordResetLoading: true,
      };

    case PASSWORD_RESET_FINISH:
      return {
        ...state,
        isPasswordResetLoading: false,
      };

    default:
      return state;
  }
};

export default loginReducer;
