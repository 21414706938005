/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import propTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { DialogTitle } from "@mui/material";

function WhatsNewView(props) {
  const { isFullScreen, showWhatsNew, onCloseWhatsNew } = props;

  return (
    <Dialog
      open={showWhatsNew}
      onClose={onCloseWhatsNew}
      fullScreen={isFullScreen}
    >
      <DialogTitle>What&apos;s New?</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6">
              2023-03-10
            </Typography>
            <Typography component="p" variant="body1">
              New Features:
            </Typography>
            <ul>
              <Typography component="li" variant="body1">
                Platform update: Screw Notes has been updated to the latest web
                standards to run faster and work better in newer web browsers.
              </Typography>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6">
              2020-08-01
            </Typography>
            <Typography component="p" variant="body1">
              New Features:
            </Typography>
            <ul>
              <Typography component="li" variant="body1">
                Cheaper! - you can now subscribe to Pro for just AUD$12/year
                (excl. tax).
              </Typography>
              <Typography component="li" variant="body1">
                Better contrast - both dark and light mode now show better
                contrast between the pages and the background.
              </Typography>
              <Typography component="li" variant="body1">
                Item Sorting - drag the Edit icon on each of your items to sort
                them!
              </Typography>
              <Typography component="li" variant="body1">
                Bulk Edit - you can now bulk complete or reset all the items on
                your pages.
              </Typography>
            </ul>
            <Typography component="p" variant="body1">
              Known Issues:
            </Typography>
            <ul>
              <Typography component="li" variant="body1">
                If you are using dark mode, some alerts may trigger the whole
                page to switch to light mode until the alert disappears.
              </Typography>
              <Typography component="li" variant="body1">
                Sorting items can sometimes have a short delay before the
                sorting can take effect, and it causes your item to jump
                backwards momentarily.
              </Typography>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6">
              2020-05-08
            </Typography>
            <Typography component="p" variant="body1">
              Bug Fixes:
            </Typography>
            <ul>
              <Typography component="li" variant="body1">
                Email Verification - we&apos;ve fixed the email verification
                problems some users were having. If you have any more problems,
                please let us know by using the <b>Contact Support</b> menu
                option.
              </Typography>
              <Typography component="li" variant="body1">
                Item Text - we&apos;ve fixed the item text sometimes going
                underneath the edit button.
              </Typography>
              <Typography component="li" variant="body1">
                Adding Items - when adding an item, the page will now scroll to
                where you&apos;re typing so you don&apos;t lose track of where
                you are.
              </Typography>
            </ul>
            <Typography component="p" variant="body1">
              Known Issues:
            </Typography>
            <ul>
              <Typography component="li" variant="body1">
                iPhone/iPad Long Tap - if you long-tap to mark an item in
                progress on iPhone, it doesn&apos;t actually do anything.
                We&apos;re looking at a fix now, but you can switch your{" "}
                <i>Completion Mode</i> in Settings to enable single-tap to mark
                things as &quot;In Progress&quot; for now.
              </Typography>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6">
              2020-05-05
            </Typography>
            <Typography component="p" variant="body1">
              New Features:
            </Typography>
            <ul>
              <Typography component="li" variant="body1">
                Completion Mode - use the Settings page to change between using
                left-click or right-click to mark items as complete.
              </Typography>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6">
              2020-05-04
            </Typography>
            <Typography component="p" variant="body1">
              New Features:
            </Typography>
            <ul>
              <Typography component="li" variant="body1">
                Dark Mode - dark mode will be detected from what your computer
                preference is set to, but you can also override it if you prefer
                a specific setting.
              </Typography>
              <Typography component="li" variant="body1">
                Settings - check out the new settings feature in the application
                menu! You can change your dark mode settings there.
              </Typography>
              <Typography component="li" variant="body1">
                This dialog - we will let you know about all new features with
                this dialog.
              </Typography>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6">
              2020-05-02
            </Typography>
            <Typography component="p" variant="body1">
              New Features:
            </Typography>
            <ul>
              <Typography component="li" variant="body1">
                Application overhaul - we&apos;ve improved performance and
                consistency of user experience across the whole app.
              </Typography>
              <Typography component="li" variant="body1">
                Better usability - most forms now accept pressing the Enter key
                to submit, instead of requiring you to click the submit buttons.
              </Typography>
            </ul>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

WhatsNewView.propTypes = {
  isFullScreen: propTypes.bool,
  showWhatsNew: propTypes.bool,
  onCloseWhatsNew: propTypes.func.isRequired,
};

WhatsNewView.defaultProps = {
  isFullScreen: false,
  showWhatsNew: false,
};

export default WhatsNewView;
