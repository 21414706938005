import React, { useContext } from 'react';
import propTypes from 'prop-types';

import DeleteAccountDialogView from './DeleteAccountDialogView';

import MenuContext from '../../../context';

import { useDeleteAccount } from '../../../../Account';

import {
  useEventEmitter,
  SEVERITY_SUCCESS,
  SEVERITY_WARNING,
  SEVERITY_ERROR,
} from '../../../../EventListener';

import {
  deleteAccountStart,
  deleteAccountFinish,
  dialogDeleteAccountClose,
} from '../../../actions';

function DeleteAccountDialogContainer(props) {
  const {
    isFullScreen,
    isDeleteAccountDialogOpen,
    isDeleteAccountDialogLoading,
  } = props;

  const dispatch = useContext(MenuContext);
  const emitEvent = useEventEmitter();

  const deleteAccount = useDeleteAccount(
    (error) => {
      dispatch(deleteAccountFinish());

      if (error.code === 'auth/requires-recent-login') {
        emitEvent(
          SEVERITY_WARNING,
          error.message,
        );
      } else {
        emitEvent(
          SEVERITY_ERROR,
          'Account deletion failed.'
            + `Received message '${error.message}'`,
        );
      }
    },
    () => {
      dispatch(deleteAccountFinish());
      emitEvent(
        SEVERITY_SUCCESS,
        'Account successfully deleted.',
      );
    },
  );

  const handleClickDeleteAccountConfirm = () => {
    dispatch(deleteAccountStart());

    deleteAccount();
  };

  const handleCloseDeleteAccountDialog = () => dispatch(dialogDeleteAccountClose());

  return (
    <DeleteAccountDialogView
      isFullScreen={isFullScreen}
      isDeleteAccountDialogOpen={isDeleteAccountDialogOpen}
      isDeleteAccountDialogLoading={isDeleteAccountDialogLoading}
      onCloseDeleteAccountDialog={handleCloseDeleteAccountDialog}
      onClickDeleteAccountConfirm={handleClickDeleteAccountConfirm}
    />
  );
}

DeleteAccountDialogContainer.propTypes = {
  isFullScreen: propTypes.bool,
  isDeleteAccountDialogOpen: propTypes.bool,
  isDeleteAccountDialogLoading: propTypes.bool,
};

DeleteAccountDialogContainer.defaultProps = {
  isFullScreen: false,
  isDeleteAccountDialogOpen: false,
  isDeleteAccountDialogLoading: false,
};

export default DeleteAccountDialogContainer;
