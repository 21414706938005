import React, {
  useCallback,
  useContext,
  useState,
} from 'react';
import propTypes from 'prop-types';

import PageView from './PageView';

import { dialogPageEditOpen } from '../actions';
import PageListContext from '../context';
import { useEventEmitter, SEVERITY_ERROR } from '../../EventListener';
import { useAddPageItem, useUpdatePageItems } from '../hooks';

function PageContainer(props) {
  const {
    hasSubscription,
    id,
    isArchived,
    title,
    createdAt,
    items,
  } = props;

  const [newItemContent, setNewItemContent] = useState('');

  const dispatch = useContext(PageListContext);
  const emitEvent = useEventEmitter();

  const editPageItems = useUpdatePageItems(
    (error) => emitEvent(
      SEVERITY_ERROR,
      error.message,
    ),
  );

  const addPageItem = useAddPageItem(
    (error) => emitEvent(
      SEVERITY_ERROR,
      error.message,
    ),
  );

  const handleSubmitEditItems = useCallback((updatedItems) => {
    editPageItems(id, updatedItems);
  }, [id, editPageItems]);

  const handleClickEditPage = useCallback(
    () => dispatch(dialogPageEditOpen(id, title)),
    [dispatch, id, title],
  );

  const handleSubmitAddItem = useCallback((e) => {
    e.preventDefault();

    addPageItem(id, newItemContent);

    setNewItemContent('');
  }, [
    id,
    newItemContent,
    addPageItem,
  ]);

  const handleChangeNewItemContent = (e, ref) => {
    ref.current.scrollIntoView(false); // false makes it scroll to bottom of the page
    setNewItemContent(e.target.value);
  };

  return (
    <PageView
      id={id}
      isArchived={isArchived}
      title={title}
      createdAt={createdAt}
      items={items}
      newItemContent={newItemContent}
      hasSubscription={hasSubscription}
      onClickEditPage={handleClickEditPage}
      onSubmitAddItem={handleSubmitAddItem}
      onSubmitEditItems={handleSubmitEditItems}
      onChangeNewItemContent={handleChangeNewItemContent}
    />
  );
}

PageContainer.propTypes = {
  hasSubscription: propTypes.bool.isRequired,
  id: propTypes.string.isRequired,
  isArchived: propTypes.bool.isRequired,
  title: propTypes.string.isRequired,
  createdAt: propTypes.number.isRequired,
  items: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      state: propTypes.number,
      content: propTypes.string,
    }),
  ),
};

PageContainer.defaultProps = {
  items: [],
};

export default PageContainer;
