import React, { useContext } from 'react';
import propTypes from 'prop-types';

import ExternalIdpListView from './ExternalIdpListView';
import LoginContext from '../context';

import {
  signInExternalStart,
  signInExternalFinish,
} from '../actions';

import { useExternalLogin } from '../../Account';

function ExternalIdpListContainer(props) {
  const {
    onError,
    onRedirect,
  } = props;

  const dispatch = useContext(LoginContext);
  const loginExternal = useExternalLogin(
    (error) => {
      onError(error);

      dispatch(signInExternalFinish());
    },
    () => {
      onRedirect();

      dispatch(signInExternalFinish());
    },
  );

  const handleClickExternalSignIn = (provider) => {
    const login = loginExternal(provider);

    return (e) => {
      dispatch(signInExternalStart());

      login(e);
    };
  };

  return (
    <ExternalIdpListView
      onClickExternalSignIn={handleClickExternalSignIn}
    />
  );
}

ExternalIdpListContainer.propTypes = {
  onError: propTypes.func.isRequired,
  onRedirect: propTypes.func.isRequired,
};

export default ExternalIdpListContainer;
