import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  avatar: {
    minWidth: '0px',
    marginRight: '4px',
  },
  listItem: {
    paddingLeft: '0px',
  },
  secondaryAction: {
    paddingRight: '0px',
  },
}));

export default useStyles;
