import React from "react";
import propTypes from "prop-types";
import { Container, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable, arrayMoveMutable } from "array-move";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import { EditNoteOutlined } from "@mui/icons-material";

import PageItem from "./PageItem";

import useStyles from "./styles";
import { ListSubheader } from "@mui/material";

function PageView(props) {
  const {
    id,
    isArchived,
    title,
    createdAt,
    items,
    newItemContent,
    hasSubscription,
    onClickEditPage,
    onSubmitAddItem,
    onSubmitEditItems,
    onChangeNewItemContent,
  } = props;

  const classes = useStyles();

  const ref = React.createRef();

  const onDrop = ({ removedIndex, addedIndex }) => {
    const newItems = arrayMoveImmutable(items, removedIndex, addedIndex);
    onSubmitEditItems(newItems);
  };

  return (
    <Paper elevation={3}>
      <Card key={id} className={classes.card} variant="outlined">
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              onClick={() => onClickEditPage(id, title)}
            >
              <EditNoteOutlined />
            </IconButton>
          }
          title={title}
          sx={{ pb: 0 }}
        />
        {!isArchived && (
          <CardContent sx={{ pt: 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {items && items.length < 1 ? (
                  <Alert severity="info">
                    <AlertTitle>No items added yet</AlertTitle>
                    Please use the text box below to add your first item.
                  </Alert>
                ) : (
                  <List
                    subheader={
                      <ListSubheader component="div">
                        {`Started: ${new Date(createdAt).toLocaleString()}`}
                      </ListSubheader>
                    }
                  >
                    <Container
                      dragHandleSelector=".drag-handle"
                      lockAxis="y"
                      onDrop={onDrop}
                    >
                      {items &&
                        items.map((item) => (
                          <Draggable key={item.id}>
                            <PageItem
                              pageId={id}
                              id={item.id}
                              state={item.state}
                              content={item.content}
                            />
                          </Draggable>
                        ))}
                    </Container>
                  </List>
                )}
              </Grid>
              <Grid item xs={12}>
                {hasSubscription || items.length <= 20 ? (
                  <form autoComplete="off" onSubmit={onSubmitAddItem}>
                    <TextField
                      label="New Item"
                      ref={ref}
                      helperText="Press enter to save your new item"
                      fullWidth
                      size="small"
                      value={newItemContent}
                      onChange={(e) => onChangeNewItemContent(e, ref)}
                    />
                  </form>
                ) : (
                  <TextField
                    label="New Item"
                    helperText="Upgrade to Pro to unlock unlimited items!"
                    fullWidth
                    value="Maximum Items Reached for Page"
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </Paper>
  );
}

PageView.propTypes = {
  id: propTypes.string.isRequired,
  isArchived: propTypes.bool,
  hasSubscription: propTypes.bool,
  title: propTypes.string.isRequired,
  createdAt: propTypes.number.isRequired,
  items: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      state: propTypes.number,
      content: propTypes.string,
    })
  ),
  newItemContent: propTypes.string,
  onClickEditPage: propTypes.func.isRequired,
  onSubmitEditItems: propTypes.func.isRequired,
  onSubmitAddItem: propTypes.func.isRequired,
  onChangeNewItemContent: propTypes.func.isRequired,
};

PageView.defaultProps = {
  isArchived: false,
  hasSubscription: false,
  items: [],
  newItemContent: "",
};

export default PageView;
