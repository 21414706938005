import React from 'react';
import propTypes from 'prop-types';

import clsx from 'clsx';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import SettingsIcon from '@mui/icons-material/Settings';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faReadme } from '@fortawesome/free-brands-svg-icons';

import AccountDialog from './AccountDialog';
import SubscriptionDialog from './SubscriptionDialog';
import SettingsDialog from './SettingsDialog';

import useStyles from './styles';

function MenuView(props) {
  const {
    isFullScreen,
    isMenuOpen,
    hasSubscription,
    anchorEl,
    onMenuClose,
    onClickSubscription,
    onClickSettings,
    onClickAccount,
    onClickLogout,
    isSubscriptionDialogOpen,
    isSubscriptionDialogLoading,
    isAccountDialogOpen,
    isAccountDialogLoading,
    isSettingsDialogOpen,
    isSettingsDialogLoading,
    isDeleteAccountDialogOpen,
    isDeleteAccountDialogLoading,
  } = props;

  const classes = useStyles();

  return (
    <>
      <Menu
        onClose={onMenuClose}
        open={isMenuOpen}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={onClickSettings}
          className={classes.menuItemBlue}
        >
          <ListItemIcon>
            <SettingsIcon className={classes.menuItemBlue} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Settings" />
        </MenuItem>
        <MenuItem
          onClick={onClickAccount}
          className={classes.menuItemBlue}
        >
          <ListItemIcon>
            <AccountBoxIcon className={classes.menuItemBlue} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Account" />
        </MenuItem>
        <MenuItem
          component="a"
          target="_blank"
          href="https://firebasestorage.googleapis.com/v0/b/screwnotes.appspot.com/o/Terms%20and%20Conditions.pdf?alt=media&token=b3ecc77d-92ba-4667-a624-5331c30310a3"
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faReadme} />
          </ListItemIcon>
          <ListItemText primary="Terms &amp; Conditions" />
        </MenuItem>
        <MenuItem
          component="a"
          target="_blank"
          href="https://firebasestorage.googleapis.com/v0/b/screwnotes.appspot.com/o/Privacy%20Policy.pdf?alt=media&token=9452d4c4-0e32-42aa-a38c-4946430655ff"
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faReadme} />
          </ListItemIcon>
          <ListItemText primary="Privacy Policy" />
        </MenuItem>
        <MenuItem
          component="a"
          target="_blank"
          href="https://blog.congruentlabs.co/tag/screw-notes/"
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faReadme} />
          </ListItemIcon>
          <ListItemText primary="Blog" />
        </MenuItem>
        <MenuItem
          component="a"
          target="_blank"
          href="mailto:support@screwnotes.com"
          className={classes.menuItemBlue}
        >
          <ListItemIcon>
            <FontAwesomeIcon className={classes.menuItemBlue} icon={faEnvelope} />
          </ListItemIcon>
          <ListItemText primary="Contact Support" />
        </MenuItem>
        <MenuItem
          onClick={onClickSubscription}
          className={clsx(
            hasSubscription && classes.menuItemRed,
            !hasSubscription && classes.menuItemGreen,
          )}
        >
          <ListItemIcon>
            {
                hasSubscription
                  ? (
                    <ThumbDownIcon
                      className={classes.menuItemRed}
                      fontSize="small"
                    />
                  )
                  : (
                    <ThumbUpIcon
                      className={classes.menuItemGreen}
                      fontSize="small"
                    />
                  )
              }
          </ListItemIcon>
          <ListItemText
            primary={
                hasSubscription
                  ? 'Cancel Pro'
                  : 'Get Pro'
              }
          />
        </MenuItem>
        <MenuItem
          onClick={onClickLogout}
          className={classes.menuItemRed}
        >
          <ListItemIcon>
            <ExitToAppIcon className={classes.menuItemRed} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </MenuItem>
      </Menu>
      <AccountDialog
        isFullScreen={isFullScreen}
        isAccountDialogOpen={isAccountDialogOpen}
        isAccountDialogLoading={isAccountDialogLoading}
        isDeleteAccountDialogOpen={isDeleteAccountDialogOpen}
        isDeleteAccountDialogLoading={isDeleteAccountDialogLoading}
      />
      <SubscriptionDialog
        isFullScreen={isFullScreen}
        hasSubscription={hasSubscription}
        isSubscriptionDialogOpen={isSubscriptionDialogOpen}
        isSubscriptionDialogLoading={isSubscriptionDialogLoading}
      />
      <SettingsDialog
        isFullScreen={isFullScreen}
        isSettingsDialogOpen={isSettingsDialogOpen}
        isSettingsDialogLoading={isSettingsDialogLoading}
      />
    </>
  );
}

MenuView.propTypes = {
  isFullScreen: propTypes.bool,
  isMenuOpen: propTypes.bool,
  hasSubscription: propTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: propTypes.object,
  onMenuClose: propTypes.func.isRequired,
  onClickSubscription: propTypes.func.isRequired,
  onClickAccount: propTypes.func.isRequired,
  onClickSettings: propTypes.func.isRequired,
  onClickLogout: propTypes.func.isRequired,
  isSubscriptionDialogOpen: propTypes.bool,
  isSubscriptionDialogLoading: propTypes.bool,
  isAccountDialogOpen: propTypes.bool,
  isAccountDialogLoading: propTypes.bool,
  isSettingsDialogOpen: propTypes.bool,
  isSettingsDialogLoading: propTypes.bool,
  isDeleteAccountDialogOpen: propTypes.bool,
  isDeleteAccountDialogLoading: propTypes.bool,
};

MenuView.defaultProps = {
  isFullScreen: false,
  isMenuOpen: false,
  hasSubscription: false,
  anchorEl: null,
  isSubscriptionDialogOpen: false,
  isSubscriptionDialogLoading: false,
  isAccountDialogOpen: false,
  isAccountDialogLoading: false,
  isSettingsDialogOpen: false,
  isSettingsDialogLoading: false,
  isDeleteAccountDialogOpen: false,
  isDeleteAccountDialogLoading: false,
};

export default MenuView;
