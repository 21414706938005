import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  loading: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default useStyles;
