/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useCallback } from "react";
import propTypes from "prop-types";
import MaterialTable from "material-table";
import { format } from "date-fns";

import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import CardActions from "@mui/material/CardActions";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Card from "@mui/material/Card";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";

import Page from "./Page";
import PageEditDialog from "./PageEditDialog";
import PageItemEditDialog from "./PageItemEditDialog";
import { useDeletePage, useReinstatePage } from "./hooks";
import { useEventEmitter, SEVERITY_ERROR } from "../EventListener";

import useStyles from "./styles";
import { Article } from "@mui/icons-material";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function PageListView(props) {
  const {
    isFullScreen,
    arePagesLoading,
    hasSubscription,
    isPageEditOpen,
    isPageItemEditOpen,
    onClickAddNewPage,
    activePageId,
    activePageTitle,
    isActivePageArchived,
    activePageItemId,
    activePageItemContent,
    pages,
    archivedPages,
  } = props;

  const classes = useStyles();
  const emitEvent = useEventEmitter();

  const reinstatePage = useReinstatePage((error) =>
    emitEvent(SEVERITY_ERROR, error.message)
  );

  const deletePage = useDeletePage((error) =>
    emitEvent(SEVERITY_ERROR, error.message)
  );

  const [showArchived, setShowArchived] = React.useState(false);

  const handleClickPageDelete = useCallback(
    (e, id) => {
      e.preventDefault();

      deletePage(id);
    },
    [deletePage]
  );

  const handleClickPageReinstate = useCallback(
    (e, id) => {
      e.preventDefault();

      reinstatePage(id);
    },
    [reinstatePage]
  );

  if (arePagesLoading) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={64} />
      </div>
    );
  }
  return (
    <>
      {showArchived ? (
        <Paper elevation={3}>
          <Card className={classes.card} variant="outlined">
            <MaterialTable
              icons={tableIcons}
              title="Archived Pages"
              columns={[
                {
                  title: "ID",
                  field: "id",
                  hidden: true,
                },
                {
                  title: "Title",
                  field: "title",
                },
                {
                  title: "Created At",
                  field: "createdAt",
                  render: (rowData) =>
                    rowData.createdAt
                      ? format(new Date(rowData.createdAt), "yyyy-MM-dd")
                      : "",
                },
                {
                  title: "Updated At",
                  defaultSort: "desc",
                  field: "updatedAt",
                  render: (rowData) =>
                    rowData.updatedAt
                      ? format(new Date(rowData.updatedAt), "yyyy-MM-dd")
                      : "",
                },
              ]}
              detailPanel={(rowData) => (
                <CardActions>
                  <Button
                    onClick={(e) => handleClickPageReinstate(e, rowData.id)}
                    size="small"
                  >
                    Restore Page
                  </Button>
                  <Button
                    onClick={(e) => handleClickPageDelete(e, rowData.id)}
                    size="small"
                  >
                    Delete Page
                  </Button>
                </CardActions>
              )}
              data={archivedPages.map((a) => ({ ...a }))} // https://stackoverflow.com/a/59648600
              options={{
                sorting: true,
                pageSize: 10,
                searchAutoFocus: true,
                actionsColumnIndex: -1,
                pageSizeOptions: [10, 20, 50],
                rowStyle: {
                  whiteSpace: "nowrap",
                },
                headerStyle: {
                  whiteSpace: "nowrap",
                },
              }}
            />
          </Card>
        </Paper>
      ) : (
        <>
          {pages &&
            pages.map((page) => (
              <Page
                id={page.id}
                key={page.id}
                isArchived={page.isArchived}
                title={page.title}
                createdAt={page.createdAt}
                items={page.items}
                hasSubscription={hasSubscription}
                showArchived={showArchived}
              />
            ))}
          <Paper className={classes.paper}>
            {hasSubscription ? (
              <Button
                onClick={onClickAddNewPage}
                color="primary"
                startIcon={<Article />}
              >
                Add New Page
              </Button>
            ) : (
              <Button
                onClick={onClickAddNewPage}
                color="primary"
                disabled={pages.length + archivedPages.length >= 3}
                startIcon={<AddIcon />}
              >
                {pages.length >= 3
                  ? "Upgrade to Pro to add more pages!"
                  : `Add New Page (${pages.length + archivedPages.length}/3)`}
              </Button>
            )}
          </Paper>
        </>
      )}

      <Box className={classes.paper}>
        <FormControlLabel
          control={
            <Switch
              checked={showArchived}
              onChange={() => setShowArchived(!showArchived)}
              name="showArchived"
            />
          }
          label="Show Archived Pages"
        />
      </Box>
      <PageEditDialog
        isFullScreen={isFullScreen}
        isPageEditOpen={isPageEditOpen}
        isPageArchived={isActivePageArchived}
        activePageId={activePageId}
        activePageTitle={activePageTitle}
      />
      <PageItemEditDialog
        isFullScreen={isFullScreen}
        isPageItemEditOpen={isPageItemEditOpen}
        activePageId={activePageId}
        activePageItemId={activePageItemId}
        activePageItemContent={activePageItemContent}
      />
    </>
  );
}

PageListView.propTypes = {
  isFullScreen: propTypes.bool,
  arePagesLoading: propTypes.bool,
  hasSubscription: propTypes.bool,
  isPageEditOpen: propTypes.bool,
  isPageItemEditOpen: propTypes.bool,
  onClickAddNewPage: propTypes.func.isRequired,
  activePageId: propTypes.string,
  activePageTitle: propTypes.string,
  isActivePageArchived: propTypes.bool,
  activePageItemId: propTypes.string,
  activePageItemContent: propTypes.string,
  pages: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      state: propTypes.number,
      content: propTypes.string,
    })
  ),
  archivedPages: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string,
      state: propTypes.number,
      content: propTypes.string,
    })
  ),
};

PageListView.defaultProps = {
  isFullScreen: false,
  arePagesLoading: false,
  hasSubscription: false,
  isPageEditOpen: false,
  isPageItemEditOpen: false,
  activePageId: "",
  activePageTitle: "",
  isActivePageArchived: false,
  activePageItemId: "",
  activePageItemContent: "",
  pages: [],
  archivedPages: [],
};

export default PageListView;
