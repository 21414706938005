import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import propTypes from 'prop-types';

import { dialogPageItemEditClose } from '../actions';
import PageListContext from '../context';
import { useUpdatePageItem, useDeletePageItem } from '../hooks';

import PageItemEditDialogView from './PageItemEditDialogView';
import { useEventEmitter, SEVERITY_ERROR } from '../../EventListener';

function PageItemEditDialogContainer(props) {
  const {
    isFullScreen,
    isPageItemEditOpen,
    activePageId,
    activePageItemId,
    activePageItemContent,
  } = props;

  const [currentPageItemContent, setCurrentPageItemContent] = useState(activePageItemContent);

  useEffect(() => setCurrentPageItemContent(activePageItemContent), [activePageItemContent]);

  const dispatch = useContext(PageListContext);
  const emitEvent = useEventEmitter();

  const updatePageItem = useUpdatePageItem(
    (error) => emitEvent(
      SEVERITY_ERROR,
      error.message,
    ),
  );

  const deletePageItem = useDeletePageItem(
    (error) => emitEvent(
      SEVERITY_ERROR,
      error.message,
    ),
  );

  const handleChangePageItemContent = (e) => setCurrentPageItemContent(e.target.value);

  const handleClosePageItemEdit = useCallback(
    () => dispatch(dialogPageItemEditClose()),
    [dispatch],
  );

  const handleClickPageItemUpdate = useCallback((e) => {
    e.preventDefault();

    updatePageItem(
      activePageId,
      activePageItemId,
      currentPageItemContent,
    );

    handleClosePageItemEdit();
  }, [
    activePageId,
    activePageItemId,
    currentPageItemContent,
  ]);

  const handleClickPageItemDelete = useCallback((e) => {
    e.preventDefault();

    deletePageItem(
      activePageId,
      activePageItemId,
    );

    handleClosePageItemEdit();
  }, [
    activePageId,
    activePageItemId,
  ]);

  return (
    <PageItemEditDialogView
      isFullScreen={isFullScreen}
      isPageItemEditOpen={isPageItemEditOpen}
      onChangePageItemContent={handleChangePageItemContent}
      onClickPageItemUpdate={handleClickPageItemUpdate}
      onClickPageItemDelete={handleClickPageItemDelete}
      onClosePageItemEdit={handleClosePageItemEdit}
      activePageItemContent={currentPageItemContent}
    />
  );
}

PageItemEditDialogContainer.propTypes = {
  isFullScreen: propTypes.bool,
  isPageItemEditOpen: propTypes.bool,
  activePageId: propTypes.string,
  activePageItemId: propTypes.string,
  activePageItemContent: propTypes.string,
};

PageItemEditDialogContainer.defaultProps = {
  isFullScreen: false,
  isPageItemEditOpen: false,
  activePageId: '',
  activePageItemId: '',
  activePageItemContent: '',
};

export default PageItemEditDialogContainer;
