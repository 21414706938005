import React, { useContext } from 'react';

import propTypes from 'prop-types';

import { usePasswordReset } from '../../Account';
import LoginContext from '../context';
import { useComponentStatusRef, useSafeState } from '../../../utils';

import {
  dialogPasswordResetClose,
  passwordResetStart,
} from '../actions';

import ResetPasswordDialogView from './PasswordResetDialogView';

function PasswordResetDialogContainer(props) {
  const {
    isPasswordResetOpen,
    isPasswordResetLoading,
  } = props;

  const componentStatus = useComponentStatusRef();

  const [email, setEmail] = useSafeState('', componentStatus);
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useSafeState(false, componentStatus);
  const [passwordResetErrorMessage, setPasswordResetErrorMessage] = useSafeState('', componentStatus);

  const dispatch = useContext(LoginContext);

  const resetPassword = usePasswordReset(
    (error) => setPasswordResetErrorMessage(error.message),
    () => setIsPasswordResetSuccess(true),
  );

  const handleClose = () => dispatch(dialogPasswordResetClose());

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleClickPasswordReset = (e) => {
    e.preventDefault();

    setIsPasswordResetSuccess(false);
    setPasswordResetErrorMessage('');

    dispatch(passwordResetStart());

    resetPassword(email);
  };

  return (
    <ResetPasswordDialogView
      isPasswordResetOpen={isPasswordResetOpen}
      isPasswordResetLoading={isPasswordResetLoading}
      isPasswordResetSuccess={isPasswordResetSuccess}
      passwordResetErrorMessage={passwordResetErrorMessage}
      onClickPasswordReset={handleClickPasswordReset}
      onClose={handleClose}
      onEmailChange={handleEmailChange}
    />
  );
}

PasswordResetDialogContainer.propTypes = {
  isPasswordResetOpen: propTypes.bool,
  isPasswordResetLoading: propTypes.bool,
};

PasswordResetDialogContainer.defaultProps = {
  isPasswordResetOpen: false,
  isPasswordResetLoading: false,
};

export default PasswordResetDialogContainer;
