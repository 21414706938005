import React from 'react';
import propTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { DialogTitle } from '@mui/material';

import ExternalIdpList from '../ExternalIdpList';
import Loadable from '../../Loadable';

import useStyles from './styles';

function SignUpDialogView(props) {
  const {
    isFullScreen,
    isSignUpOpen,
    isSignUpLoading,
    arePasswordsMatching,
    hasPasswordRepeatedHadFocus,
    signUpErrorMessage,
    onClickSignUp,
    onClose,
    onEmailChange,
    onPasswordChange,
    onPasswordRepeatedChange,
    onPasswordRepeatedFocus,
    onError,
    onRedirect,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={isSignUpOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={isFullScreen}
    >
      <DialogTitle>
        Sign Up To Screw Notes
      </DialogTitle>
      <DialogContent>
        <Loadable isLoading={isSignUpLoading}>
          <form
            onSubmit={onClickSignUp}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Alert severity="info">
                  <Typography>
                    If you don&apos;t have an account, please sign up to start
                    using Screw Notes.
                  </Typography>
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  fullWidth
                  required
                  id="signUpEmail"
                  onChange={onEmailChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Password"
                  type="password"
                  required
                  fullWidth
                  id="signUpPassword"
                  onChange={onPasswordChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  classes={{
                    root: classes.validationField,
                  }}
                  error={hasPasswordRepeatedHadFocus && !arePasswordsMatching}
                  label="Password Repeated"
                  type="password"
                  required
                  fullWidth
                  id="signUpPasswordRepeated"
                  onChange={onPasswordRepeatedChange}
                  onFocus={onPasswordRepeatedFocus}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button
                  className={classes.submitButton}
                  type="submit"
                  variant="contained"
                  size="large"
                  startIcon={<FontAwesomeIcon icon={faEnvelope} />}
                >
                  Sign Up With Email
                </Button>
              </Grid>
              {signUpErrorMessage && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    <Typography>
                      {signUpErrorMessage}
                    </Typography>
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <ExternalIdpList
                  onError={onError}
                  onRedirect={onRedirect}
                />
              </Grid>
            </Grid>
          </form>
        </Loadable>
      </DialogContent>
    </Dialog>
  );
}

SignUpDialogView.propTypes = {
  isFullScreen: propTypes.bool,
  isSignUpOpen: propTypes.bool,
  isSignUpLoading: propTypes.bool,
  arePasswordsMatching: propTypes.bool,
  hasPasswordRepeatedHadFocus: propTypes.bool,
  signUpErrorMessage: propTypes.string,
  onClickSignUp: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  onEmailChange: propTypes.func.isRequired,
  onPasswordChange: propTypes.func.isRequired,
  onPasswordRepeatedChange: propTypes.func.isRequired,
  onPasswordRepeatedFocus: propTypes.func.isRequired,
  onError: propTypes.func.isRequired,
  onRedirect: propTypes.func.isRequired,
};

SignUpDialogView.defaultProps = {
  isFullScreen: false,
  isSignUpOpen: false,
  isSignUpLoading: false,
  arePasswordsMatching: false,
  hasPasswordRepeatedHadFocus: false,
  signUpErrorMessage: '',
};

export default SignUpDialogView;
